import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';

const GlobalStyle = createGlobalStyle`

  :root {
	--background-color: rgba(247, 245, 242, 1);
	--menu-color: ${({ menuColor }) => (menuColor ? 'rgba(247, 245, 242, 1)' : 'rgb(31, 48, 45)')}; 
    --creamy: rgba(216,199,185,1);
	--creamy-light: rgb(236,228,217);
	--shape: "M0,488V183.878H.142C4.051,81.663,88.005,0,191,0S377.949,81.663,381.858,183.878H382V488Z";
	--shape-rwd: "M0,311V117.185H.091a122,122,0,0,1,243.818,0H244V311Z";
	--inner-height: 100vh;
  }
  
  // rems for 16px
  // 10px = 0.625rem
  // 11px = 0.6875rem
  // 12px = 0.75rem
  // 13px = 0.8125rem
  // 14px = 0.875rem
  // 15px = 0.9376rem
  // 16px = 1rem
  // 17px = 1.0625rem
  // 18px = 1.125rem
  // 19px = 1.1876rem
  // 20px = 1.25rem
  // 22px = 1.375rem
  // 23px = 1.4375rem
  // 24px = 1.5rem
  // 25px = 1.5625rem
  // 26px = 1.625rem
  // 30px = 1.875rem

  html {
    transition: background-color 0.1s ease-in;
    background-color: var(--background-color);
    padding: 0;
    caret-color: rgba(0, 0, 0, 0);
  }

  body {
	cursor: none;
	padding: 0;
    -webkit-overflow-scrolling: touch;
	margin: 0;
    height: var(--inner-height);
    position: relative;
    scroll-behavior: smooth;
    // overflow: ${({ introStartModule }) => (introStartModule ? 'hidden' : '')};
    //background-color: rgba(247,245, 242, 1);
	font-family: ${({ theme }) => theme.fontFamily.primary};
    color: rgba(28, 28, 28, 1);
    -webkit-tap-highlight-color: transparent !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

    ::-webkit-scrollbar {
      background: transparent;
      width: 0;
    }
	
  }


  *, *::before, *::after {
	box-sizing: border-box;
  }

  *::selection {
    background-color:${({ theme }) => theme.colors.lightBrown_50};
  }
  
  textarea:focus, input:focus {
	outline: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
	margin: 0;
	padding: 0;
  }

  ul {
	list-style-type: none;
  }
  
  a {
	text-decoration: none;
	color: inherit;
	cursor: none;
  }
  
  button {
	cursor: none;
  }
  span.open-cookies {
      cursor: pointer;
  }
  .last{
	z-index: 1;
	* {
	  z-index: inherit;
	}
	.pnimgrab, .pnlm-render-container{
	  z-index: initial;
	}
  }
  .pnlm-about-msg {
    display: none!important;
  }

  .pnlm-grab, .pnlm-grabbing {
    cursor: none !important;
  }
  
	.arrow-button{
      background-color: ${({ theme }) => theme.colors.darkGreen};
      border-radius: 50%;
      //cursor: pointer;
      height: 72px;
      width: 72px;
      border: none;
      transition: 0.2s;
      position: relative;
      @media (max-width: 767px) {
        height: 49px;
        width: 49px;
      }

      :hover {
        transform: scale(1.22);
      }

      img {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
	}
	
	#fullpage {
	  z-index: 1;
	}
	
	.model-label-wrapper {
	  overflow: unset !important;
	  &.soon {
        opacity: 0.5;
      }

      z-index: 11 !important;
	  div {
		pointer-events: none !important;
	  }
	}
	
	.underline-animation{
      &.btn {
        visibility: hidden;
      }
      :before,
      :after {
        background: currentColor;
        height: 1px;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: calc(100% + 2px);
        width: 100%;
      }
      :before {
        content: '';
        transform: scale3d(0, 1, 1);
        transform-origin: 0% 50%;
        transition: transform 0.3s;
      }
      :hover:before {
        transform: scale3d(1, 1, 1);
      }
      :after {
        content: '';
        top: calc(100% + 6px);
        transform-origin: 100% 50%;
        transition: transform 0.3s;
      }
      :hover:after {
        transform: scale3d(0, 1, 1);
      }
	}
`;

export default connect((state) => ({
	menuColor: state.menuColor.menuColor,
	introStartModule: state.introStartModule.introStartModule,
}))(GlobalStyle);
