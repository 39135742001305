import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContainer from '../../common/PageContainer';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import LogoSvg from '../../assets/logo/logo-gray.svg';
import SocialMediaIcons from '../socialMediaIcons/SocialMediaIcons';
import ArrowButton from '../../common/ArrowButton';
import getSlug from '../../utils/getSlug';
import LogoSVG from '../../assets/logo/PLEO_digital.inline.svg';
import NohoSVG from '../../assets/logo/noho_investment_logo.inline.svg';
import {useDispatch } from "react-redux";
import {setShowModalNoMatterWhat} from "../../state/necessaryCookiesSlice";

const StyledFooterWrapper = styled.div`
	align-items: flex-start;
	background-color: ${({ theme }) => theme.colors.black};
	bottom: 0;
	display: none;
	justify-content: flex-start;
	min-height: 100vh;
	position: sticky;
	scroll-behavior: smooth;
	width: 100%;
	@media (min-width: 1366px) {
		display: inline-block;
	}
	z-index: 0;
	#footer {
		span {
			&.footer-info {
				color: ${({ theme }) => theme.colors.grey};
				font-family: ${({ theme }) => theme.fontFamily.secondary};
				//font-size: 14px;
				font-size: ${({ isUA }) => (isUA ? '16px' : '14px')};
				font-weight: ${({ theme }) => theme.fontWeight.medium};
				line-height: 20px;
			}
			&.footer-header-text {
				color: ${({ theme }) => theme.colors.white};
				font-family: ${({ theme }) => theme.fontFamily.primary};
				font-size: 64px;
				font-weight: ${({ theme }) => theme.fontWeight.light};
				line-height: 72px;
				@media (max-width: 768px) {
					font-size: 24px;
					line-height: 34px;
				}
			}
		}
		a {
			color: ${({ theme }) => theme.colors.white};
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			font-size: ${({ isUA }) => (isUA ? '16px' : '14px')};
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			line-height: 30px;
			&.contact {
				margin-right: 27px;
				:hover + a,
				button {
					transform: scale(1.22);
				}
				&.active {
					color: ${({ theme }) => theme.colors.lightBrown};
				}
			}
			:hover {
				color: ${({ theme }) => theme.colors.lightBrown};
				span {
					color: ${({ theme }) => theme.colors.lightBrown};
				}
			}
		}

		div {
			.inner-html {
				color: ${({ theme }) => theme.colors.grey};
				font-family: ${({ theme }) => theme.fontFamily.secondary};
				font-size: ${({ isUA }) => (isUA ? '16px' : '14px')};
				font-weight: ${({ theme }) => theme.fontWeight.medium};
				letter-spacing: 0;
				line-height: 20px;
				margin-top: 3vh;
				@media (max-width: 767px) {
					margin-top: 3vh;
				}
				@media (min-width: 768px) and (max-width: 1365px) {
					margin-top: 3vh;
				}
			}
		}
	}
`;

const StyledFooterContainerTop = styled.div`
	border-bottom: solid 0.1em ${({ theme }) => theme.colors.grey};
	margin-top: 40px;
	position: relative;
	width: 100%;
	> :nth-child(1) {
		padding-top: 3%;
	}
	> :nth-child(2) {
		padding-bottom: 5%;
		padding-top: 3%;
	}
	img {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
`;

const StyledFooterContainerBottom = styled.div`
	width: 100%;

	div:nth-child(2) {
		align-items: start;
	}
`;

const StyledFooterContainerRow = styled.div`
	align-items: center;
	display: flex;
	margin-top: 3%;
	width: 100%;
	justify-content: left;
	a {
		cursor: pointer;
	}
	&.column {
		flex-direction: column;
		align-items: start;
		row-gap: 32px;
		margin-top: 0;
		> div {
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			color: ${({ theme }) => theme.colors.grey};
			max-width: 50%;
		}
		#logo_noho {
			width: 160px;
		}
	}
`;

const StyledFooterContainerItem = styled.div`
	:nth-of-type(3) {
		text-align: end;
	}
	a,
	img {
		cursor: pointer;
	}

	width: 33.333%;
`;

const StyledAuthorInfo = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 90px;
	a {
		display: flex;
		align-items: center;
		height: 100%;
		color: ${({ theme }) => theme.colors.grey} !important;
		font-size: ${({ isUA }) => (isUA ? '17px' : '14px')} !important;
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		font-family: ${({ theme }) => theme.fontFamily.secondary};
	}
	svg {
		margin-left: 6px;
		margin-top: 2px;
		path {
			fill: ${({ theme }) => theme.colors.grey};
		}
	}
`;

const FooterDesktop = ({ footerData, activeLang, immutablePageId }) => {
	const isUA = activeLang === 'ua';
	const header = footerData?.footerHeader || '';
	const buttonText = footerData?.footerButtonText || '';
	const buttonLink = footerData?.footerButtonLink?.[0]?.slug || '';
	const phoneNumber = footerData?.generalInfoPhoneNumber || '';
	const email = footerData?.generalInfoEmail || '';
	const investor = footerData?.footerInvestor || '';
	const privacyPolicy = footerData?.footerPrivacyPolicy || '';
	const privacyPolicyLink = footerData?.footerPrivacyPolicyLink?.slug || '';
	const address = footerData?.generalInfoAddress || '';
	const disclaimer = footerData?.footerDisclaimer || '';
	const description = footerData?.footerDescription || '';
	const dispatch = useDispatch();
	const toggleActiveClass = (link, button) => {
		button.onmouseover = () => {
			link.classList.add('active');
		};
		button.onmouseout = () => {
			link.classList.remove('active');
		};
	};

	useEffect(() => {
		const button = document.querySelector('.on-footer');
		toggleActiveClass(button.previousSibling, button);
	}, []);
	const handleCookiesModal = () => {
		dispatch(setShowModalNoMatterWhat(true))
	}
	const manageCookiesText = activeLang === 'pl' ? 'Zarządzaj plikami cookie' : activeLang === 'ua' ? 'Керувати файлами cookie': 'Manage cookies';
	return (
		<StyledFooterWrapper isUA={isUA}>
			<PageContainer id="footer" className="content-standard">
				<StyledFooterContainerTop>
					<StyledFooterContainerRow className={'column'}>
						{/*<span className="footer-header-text">{header}</span>*/}
						<NohoSVG id="logo_noho" />
						<div dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)} />
					</StyledFooterContainerRow>
					<StyledFooterContainerRow>
						<Link className="contact" to={`/${getSlug(buttonLink, activeLang)}`} data-cursor="hidden-mode">
							{buttonText}
						</Link>
						<ArrowButton to={`/${getSlug(buttonLink, activeLang)}`} onFooter />
					</StyledFooterContainerRow>
				</StyledFooterContainerTop>
				<StyledFooterContainerBottom>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							{immutablePageId !== 'home_id' ? (
								<Link to={`/${getSlug('', activeLang)}`}>
									<img src={LogoSvg} alt="Dolnych Młynów 10" />
								</Link>
							) : (
								<img
									src={LogoSvg}
									onClick={(e) => {
										window.scrollTo({
											top: 0,
											behavior: 'smooth',
										});
									}}
									alt="Dolnych Młynów 10"
								/>
							)}
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<a href={`tel:${phoneNumber}`} data-cursor="hidden-mode">
								<span className="footer-info">{phoneNumber}</span>
							</a>
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<a href={`mailto:${email}`} data-cursor="hidden-mode">
								<span className="footer-info">{email}</span>
							</a>
						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							<a href="https://www.nohoinvestment.com/" data-cursor="hidden-mode">
								<span className="footer-info">{investor}</span>
							</a>

							<SocialMediaIcons inFooter data={footerData} />
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<Link to={`/${getSlug(privacyPolicyLink, activeLang)}`} data-cursor="hidden-mode">
								<span className="footer-info">{privacyPolicy}</span>
							</Link> <br/>
							<span data-cursor="hidden-mode" onClick={() => handleCookiesModal()} className="footer-info open-cookies">{manageCookiesText}</span>
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<span className="footer-info address">{address}</span>
							<div className="inner-html" dangerouslySetInnerHTML={GetHtmlFromRedactorField(disclaimer)} />
						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
				</StyledFooterContainerBottom>
				<StyledAuthorInfo isUA={isUA}>
					<a href="https://pleodigital.com/" target="_blank" rel="nofollow" data-cursor="blend-mode-circle">
						developed by
						<LogoSVG />
					</a>
				</StyledAuthorInfo>
			</PageContainer>
		</StyledFooterWrapper>
	);
};

export default FooterDesktop;
