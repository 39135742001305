import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import uuid from 'react-uuid';
import { setLang } from '../../state/languageSlice';
import getSlug from '../../utils/getSlug';
import { setIntroLoadedOnce } from '../../state/introLoadedOnce';
import { setIsDropdownMenuOpen, setTopMovement } from '../../state/isDropdownMenuOpenSlice';

const ChangeLanguageWrapper = styled.div`
	opacity: 1;
	position: relative;

	@media (min-width: 1000px) {
		left: unset;
	}

	&.visible {
		opacity: 1;

		-events: all;
		transition: opacity 0.6s;
		transition-delay: 0.8s;
	}

	&.hidden {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s;
	}
`;

const CurrentLanguageInHiddenList = styled.div`
	//cursor: pointer;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 25px;

	span {
		height: 45px;

		&:hover {
			color: ${({ theme, color }) => (color ? 'rgba(86, 87, 86, 1)' : theme.colors.lightBrown)};
		}
	}
`;

const CurrentLanguage = styled.div`
	//cursor: pointer;
	align-items: center;
	color: var(--menu-color);
	display: flex;
	justify-content: center;
	position: relative;
	width: 25px;

	&:hover {
		span {
			color: ${({ theme, color }) => (color ? 'rgba(86, 87, 86, 1)' : theme.colors.lightBrown)};
		}
	}
`;

const OtherLanguagesList = styled.div`
	opacity: 0;
	padding-top: 28px;
	pointer-events: none;
	position: absolute;
	top: 20px;
	transform: translateY(-45px);
	transition: transform 0.3s 0.1s ease-in-out, opacity 0.3s 0.1s ease-in-out;
	&.expanded {
		align-items: center;
		display: flex;
		justify-content: center;
		opacity: 1;
		pointer-events: all;
		transform: translateY(0);
		z-index: 1;
	}
`;

const StyledListOnDropdown = styled.div`
	display: flex;

	span {
		margin-right: 30px;
		&.dropdown {
			cursor: pointer;
		}

		&:last-of-type {
			margin-right: 0;
		}

		&:hover {
			color: ${({ theme, color }) => (color ? 'rgba(86, 87, 86, 1)' : theme.colors.lightBrown)};
		}

		&.active {
			color: ${({ theme, color }) => (color ? 'rgba(86, 87, 86, 1)' : theme.colors.lightBrown)};
		}
	}
`;

const languageOptions = ['pl', 'en', 'ua'];

const ChangeLangSelector = ({ activeLang, otherPagesPathsForLangChange, onDropdownMenu, immutablePageId }) => {
	const [isSelectorOpened, setIsSelectorOpened] = useState(false);
	const otherLanguages = languageOptions.filter((item) => item !== activeLang);
	const dispatch = useDispatch();
	const selectorRef = useRef();
	const toggleSelectorState = () => {
		setIsSelectorOpened(!isSelectorOpened);
	};

	const setStateToActiveSite = (dispatch, site) => {
		if (site === 'en') {
			dispatch(setLang(languageOptions[1]));
			dispatch(setIntroLoadedOnce(true));
		} else if (site === 'ua') {
			dispatch(setLang(languageOptions[2]));
			dispatch(setIntroLoadedOnce(true));
		} else {
			dispatch(setLang(languageOptions[0]));
			dispatch(setIntroLoadedOnce(true));
		}
	};

	const handleChange = (selectedOption, pathToNavigate, lang) => {
		const offsetTop = window.scrollY;
		if (selectedOption !== lang) {
			// if (this.shouldRemember) {
			// 	window.localStorage.setItem('appUILang', selectedOption);
			// }
			setStateToActiveSite(dispatch, selectedOption);
			toggleSelectorState();
			const slugToNavigate = getSlug(pathToNavigate, selectedOption);
			immutablePageId !== 'investment_id' && dispatch(setTopMovement(offsetTop));
			dispatch(setIsDropdownMenuOpen(false));
			navigate(`/${slugToNavigate}`);
		}
	};

	const handleClickOutside = (e) => {
		if (selectorRef?.current?.contains(e.target)) {
			return;
		}
		if (isSelectorOpened) {
			setIsSelectorOpened(false);
		}
	};

	useEffect(() => {
		!onDropdownMenu && document.addEventListener('mousedown', handleClickOutside, false);

		return () => {
			!onDropdownMenu && document.removeEventListener('mousedown', handleClickOutside, false);
		};
	}, [isSelectorOpened]);

	return (
		<>
			{!onDropdownMenu ? (
				<ChangeLanguageWrapper className="change-lang custom-cursor-animation" ref={selectorRef}>
					<CurrentLanguage onClick={() => toggleSelectorState()}>
						<span>{activeLang}</span>
					</CurrentLanguage>
					<OtherLanguagesList className={isSelectorOpened ? 'expanded' : ''} data-cursor="blend-mode-circle">
						<CurrentLanguageInHiddenList>
							{otherLanguages.map((otherLang) => {
								const otherLangUkraineFix = otherLang === 'ua' ? 'uk' : otherLang;
								return (
									<span
										key={uuid()}
										onClick={() => {
											handleChange(otherLang, otherPagesPathsForLangChange[otherLangUkraineFix], activeLang);
										}}
									>
										{otherLang}
									</span>
								);
							})}
						</CurrentLanguageInHiddenList>
					</OtherLanguagesList>
				</ChangeLanguageWrapper>
			) : (
				<ChangeLanguageWrapper className="change-lang custom-cursor-animation">
					<StyledListOnDropdown>
						{languageOptions.map((lang) => {
							const otherLangUkraineFix = lang === 'ua' ? 'uk' : lang;
							return (
								<span
									key={uuid()}
									data-cursor={onDropdownMenu ? 'hidden-mode' : 'blend-mode-circle'}
									className={`${lang === activeLang ? 'active' : ''} ${onDropdownMenu ? 'dropdown' : ''}`}
									onClick={() => {
										handleChange(lang, otherPagesPathsForLangChange[otherLangUkraineFix], activeLang);
									}}
								>
									{lang}
								</span>
							);
						})}
					</StyledListOnDropdown>
				</ChangeLanguageWrapper>
			)}
		</>
	);
};

export default ChangeLangSelector;
