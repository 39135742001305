import { createSlice } from '@reduxjs/toolkit';

export const isDropdownMenu = createSlice({
	name: 'IsDropdownMenu',
	initialState: {
		isDropdownMenu: false,
	},
	reducers: {
		setIsDropdownMenu: (state, action) => {
			state.isDropdownMenu = action.payload;
		},
	},
});

export const { setIsDropdownMenu } = isDropdownMenu.actions;

export default isDropdownMenu.reducer;
