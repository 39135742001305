const themeSettings = {
	colors: {
		black: 'rgb(28,28,28)',
		blackWithOpacity: 'rgba(28,28,28, 0.5)',
		white: 'rgb(247,245,242)',
		darkGreen: 'rgb(31,48,45)',
		brown: 'rgb(49,49,49)',
		green: 'rgba(31, 48, 45, 1)',
		grey: 'rgb(160,157,146)',
		greyAlpha: 'rgba(160,157,146,0.3)',
		graphite: 'rgb(86,87,86)',
		lightBrown: 'rgb(175,135,92)',
		lightBrown_50: '#CEB69A',
		creamyLight: 'rgb(236,228,217)',
		creamyLight_50: 'rgba(236,228,217,0.5)',
		creamy: 'rgba(216,199,185,1)',
		gold: 'rgb(175,135,92)',
		lightBlue: 'rgb(204,217,236)',
		statusGreen: 'rgb(45, 118,90)',
		statusRed: 'rgb(155, 46,46)',
		statusGreenHovered: 'rgb(71, 165, 128)',
		statusRedHovered: 'rgb(195, 42,42)',
		statusGreyHovered: 'rgb(216, 199, 185)',
		plansBgCream: 'rgb(244,241,233)',
		nohoActiveColor: `rgb(160, 157, 146)`,
	},

	fontFamily: {
		primary: 'Ivy Mode, sans-serif',
		primaryItalic: 'Ivy Mode Italic, sans-serif',
		secondary: 'Sofia Pro, serif',
	},

	fontWeight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
	},
};

export default themeSettings;
