import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import getSlug from '../../utils/getSlug';
import PageContainer from '../../common/PageContainer';
import LogoSvg from '../../assets/logo/logo.inline.svg';
import LogoTextSvg from '../../assets/logo/logo-text.svg';
import LogoTextWhiteSvg from '../../assets/logo/logo-text-white.svg';
import DropdownMenu from './DropdownMenu';
import { setIsDropdownMenu } from '../../state/isDropdownMenuSlice';
import { setIsDropdownMenuOpen } from '../../state/isDropdownMenuOpenSlice';
import { setActiveBuilding, setActiveFlat } from '../../state/modelSlice';
import ChangeLangSelector from './ChangeLangSelector';

const StyledMenuWrapper = styled.div`
	display: none;
	@media (min-width: 1024px) {
		align-items: center;
		color: var(--menu-color);
		position: absolute;
		top: 0;
		display: ${({ scaleIntroAnimation }) => (scaleIntroAnimation ? 'flex' : 'none')};
		font-family: ${({ theme }) => theme.fontFamily.secondary};
		font-size: ${({ isUA }) => (isUA ? '16px' : '14px')};
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		//height: 110px;
		z-index: 11;
		height: 140px;
		padding-top: 30px;
		//justify-content: space-between;
		line-height: 20px;
		//margin-top: 30px;
		width: 100%;
		transition: top 0.35s;
		transition-delay: 0.35s;
		a {
			color: var(--menu-color);
		}
		&.hide {
			transition: top 0.1s ease-in-out;
			transition-delay: 0s;
			top: -140px;
		}
	}
`;

const StyledSingleMenuItem = styled(Link)`
	//cursor: pointer;
	margin-right: 40px;

	&:hover {
		color: ${({ theme }) => theme.colors.lightBrown};
	}

	&.active {
		color: ${({ theme, $color }) => ($color ? theme.colors.graphite : theme.colors.grey)};
	}

	&#noho_id {
		&.active {
			color: ${({ theme }) => theme.colors.grey};
		}
	}
`;

const StyledLogoWrapperSmall = styled.div`
	//height: 75px;
	position: absolute;
	z-index: ${({ scaleIntroAnimation }) => (scaleIntroAnimation ? '13' : '0')};
	top: 55px;
	display: none;
	&.hidden {
		display: none;
	}

	@media (min-width: 768px) and (max-width: 1023px) {
		top: 54px;
		display: flex;
		left: 0;
		//&.right-panel-opened {
		//	left: -40px;
		//	top: 25px;
		//}
	}
	@media (max-width: 767px) {
		display: flex;
		top: 24px;
		left: 0;
	}
`;
const StyledLogoWrapper = styled(Link)`
	width: 85px;

	&.text {
		width: 110px;
		height: 42px;
		// margin-top: 30px;
	}

	@media (min-width: 1024px) {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		&.text {
			margin-top: 55px;
		}
	}

	svg {
		path,
		rect {
			fill: var(--menu-color);
		}
	}
`;
const StyledMenuForModalAnimation = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease-in;
	width: 100%;

	&.show-animated {
		opacity: 1;
		pointer-events: auto;
		transition-delay: 0.5s;
	}

	&.show {
		opacity: 1;
		pointer-events: auto;
		transition: none;
	}
`;

const StyledLogoWrapperAnimated = styled(StyledLogoWrapper)`
	display: none;
	@media (min-width: 1024px) {
		position: absolute;
		left: 0;
		display: flex;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s 0.2s ease-in;

		&.show {
			opacity: 1;
			pointer-events: auto;
		}
		&.dark {
			svg {
				path {
					fill: ${({ theme }) => theme.colors.black};
				}
			}
		}
	}
`;
const StyledMenuWithLangWrapper = styled.div`
	display: flex;
`;

const StyledLangChangeWrapper = styled.div``;

const Menu = ({ menuData, activeLang, pageContext, location }) => {
	const isUA = activeLang === 'ua';
	// const [isDropdownMenu, setIsDropdownMenu] = useState(false);
	const { otherPagesPathsForLangChange } = pageContext;
	const [activePage, setActivePage] = useState(1);
	const menuTopItems = menuData?.menuTopItems || [];
	const scaleIntroAnimation = useSelector((state) => state.scaleIntroAnimation.scaleIntroAnimation);
	const menuMainPageItem = menuTopItems.filter((item) => item.immutableId === 'home_page_id');
	const logoSlug = menuMainPageItem?.menuItemLink?.slug || '';
	const menuWithoutMainPage = menuTopItems.filter((item) => item.immutableId !== 'home_page_id');
	const menuLeftSide = menuWithoutMainPage.slice(0, 4);
	const menuRightSide = menuWithoutMainPage.slice(-3);
	const isDropdownMenu = useSelector((state) => state.isDropdownMenu.isDropdownMenu);
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);
	const dispatch = useDispatch();
	const immutablePageId = pageContext?.immutableId || '';
	const activeBuilding = useSelector((state) => state.model.activeBuilding);
	const activeFlat = useSelector((state) => state.model.activeFlat);
	const color = useSelector((state) => state.menuColor.menuColor);
	const is3DModelViewActive = useSelector((state) => state.model.is3DModelViewActive);
	const isRightPanelFromMenuOpened = useSelector((state) => state.model.isRightPanelFromListOpened);
	const menuKeyValueObject = {};
	menuTopItems.forEach((item, key) => {
		menuKeyValueObject[item.immutableId] = key + 1;
	});

	const handleMenuItemClick = (activeMenuItemNumber) => {
		if (activePage !== activeMenuItemNumber) {
			setActivePage(activeMenuItemNumber);
		}
	};

	const getPageIdFromPathname = () => {
		const activeId = menuTopItems.findIndex((item) => {
			if (location.pathname === '/') {
				return true;
			}
			return item.menuItemLink?.slug && location.pathname.includes(item.menuItemLink?.slug);
		});
		setActivePage(activeId + 1);
	};

	useEffect(() => {
		getPageIdFromPathname();
	}, [location.pathname]);

	const handleLogoOnModelClick = (activeMenuItemNumber) => {
		handleMenuItemClick(activeMenuItemNumber);
		if (activeBuilding !== '') {
			dispatch(setActiveBuilding(''));
		}
		if (activeFlat !== '') {
			dispatch(setActiveFlat(''));
		}
	};

	const closeDropdownMenuIfOpen = () => {
		if (isDropdownMenuOpen) {
			dispatch(setIsDropdownMenuOpen(false));
		}
	};
	const handleMenuOnScroll = () => {
		const windowWidth = window?.innerWidth || 0;
		if (windowWidth >= 1024) {
			if (window.scrollY >= 140 && !isDropdownMenu) {
				dispatch(setIsDropdownMenu(true));
			} else if (!isDropdownMenuOpen && window.scrollY < 140 && isDropdownMenu) {
				dispatch(setIsDropdownMenu(false));
			}
		}
	};

	const handleMenuOnResize = () => {
		const windowWidth = window?.innerWidth || 0;
		const windowHeight = window?.innerHeight || 0;
		document.documentElement.style.setProperty('--inner-height', `${windowHeight}px`);
		if (windowWidth < 1024 || window.scrollY > windowHeight) {
			dispatch(setIsDropdownMenu(true));
		} else if (windowWidth >= 1024) {
			closeDropdownMenuIfOpen();
			dispatch(setIsDropdownMenu(false));
		}
	};

	const setInitialActiveSite = () => {
		const activeOriginalId = pageContext.originalId;
		const activeItem = menuTopItems.find((item) => item.menuItemLink.originalId === activeOriginalId);
		const initialActivePageImmutableId = activeItem?.immutableId || 'home_page_id';
		const initialActivePage = menuKeyValueObject[initialActivePageImmutableId];
		setActivePage(initialActivePage);
	};
	useEffect(() => {
		setInitialActiveSite();
		handleMenuOnResize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleMenuOnResize);

		return () => {
			window.removeEventListener('resize', handleMenuOnResize);
		};
	}, [isDropdownMenuOpen]);
	useEffect(() => {
		window.addEventListener('scroll', handleMenuOnScroll);

		return () => {
			window.removeEventListener('scroll', handleMenuOnScroll);
		};
	}, [isDropdownMenu, isDropdownMenuOpen]);

	const isAnyApartmentSelected = activeFlat !== '';

	return (
		<PageContainer className="content-standard menu">
			<>
				{isDropdownMenu && (
					<StyledLogoWrapperSmall
						className={isRightPanelFromMenuOpened ? 'right-panel-opened' : ''}
						scaleIntroAnimation={scaleIntroAnimation}
					>
						{!isAnyApartmentSelected && (
							<StyledLogoWrapper
								className="text"
								to={`/${getSlug(logoSlug, activeLang)}`}
								onClick={() => handleMenuItemClick(menuKeyValueObject.home_page_id)}
							>
								{color ? <img alt="logo-white" src={LogoTextWhiteSvg} /> : <img alt="logo" src={LogoTextSvg} />}
							</StyledLogoWrapper>
						)}
					</StyledLogoWrapperSmall>
				)}
				<DropdownMenu
					menuData={menuData}
					activePage={activePage}
					activeLang={activeLang}
					menuKeyValueObject={menuKeyValueObject}
					handleMenuItemClick={handleMenuItemClick}
					otherPagesPathsForLangChange={otherPagesPathsForLangChange}
					immutablePageId={immutablePageId}
				/>
			</>
			<StyledMenuWrapper id="main-menu" scaleIntroAnimation={scaleIntroAnimation} isUa={isUA}>
				{pageContext?.immutableId === 'apartments_id' && (is3DModelViewActive || isRightPanelFromMenuOpened) && (
					<StyledLogoWrapperAnimated
						data-cursor="blend-mode-circle"
						className={`${activeBuilding !== '' ? 'show' : ''} ${activeFlat !== '' ? 'dark' : ''}`}
						to={`/${getSlug(logoSlug, activeLang)}`}
						onClick={() => handleLogoOnModelClick(menuKeyValueObject.home_page_id)}
					>
						<LogoSvg />
					</StyledLogoWrapperAnimated>
				)}

				<StyledMenuForModalAnimation
					className={`${activeBuilding === '' && pageContext?.immutableId === 'apartments_id' ? 'show-animated' : ''} ${
						pageContext?.immutableId !== 'apartments_id' || (!is3DModelViewActive && !isRightPanelFromMenuOpened) ? 'show' : ''
					}`}
				>
					<div>
						{menuLeftSide.map((item) => {
							const slug = item?.menuItemLink?.slug || '';
							const id = item?.immutableId || '';
							return (
								<StyledSingleMenuItem
									data-cursor="blend-mode-circle"
									id={id}
									$color={color}
									activeClassName="active"
									// className={activePage === menuKeyValueObject[item.immutableId] ? 'active' : ''}
									key={item.originalId}
									to={`/${getSlug(slug, activeLang)}`}
									onClick={() => handleMenuItemClick(menuKeyValueObject[item.immutableId])}
								>
									{item.menuItemName}
								</StyledSingleMenuItem>
							);
						})}
					</div>
					<StyledLogoWrapper
						data-cursor="blend-mode-circle"
						to={`/${getSlug(logoSlug, activeLang)}`}
						onClick={() => handleMenuItemClick(menuKeyValueObject.home_page_id)}
					>
						<LogoSvg />
					</StyledLogoWrapper>
					<StyledMenuWithLangWrapper>
						{menuRightSide.map((item) => {
							const slug = item?.menuItemLink?.slug || '';
							const id = item?.immutableId || '';
							return (
								<StyledSingleMenuItem
									id={id}
									$color={color}
									data-cursor="blend-mode-circle"
									activeClassName="active"
									// className={activePage === menuKeyValueObject[item.immutableId] ? 'active' : ''}
									key={item.originalId}
									to={`/${getSlug(slug, activeLang)}`}
									onClick={() => handleMenuItemClick(menuKeyValueObject[item.immutableId])}
								>
									{item.menuItemName}
								</StyledSingleMenuItem>
							);
						})}
						<StyledLangChangeWrapper data-cursor="blend-mode-circle">
							<ChangeLangSelector activeLang={activeLang} otherPagesPathsForLangChange={otherPagesPathsForLangChange} />
						</StyledLangChangeWrapper>
					</StyledMenuWithLangWrapper>
				</StyledMenuForModalAnimation>
			</StyledMenuWrapper>
		</PageContainer>
	);
};

export default Menu;
