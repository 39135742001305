export default async function textBtnAnimation(tl11, textDesc, activeBtn) {
	tl11.fromTo(
		textDesc,
		{
			autoAlpha: 0,
			ease: 'power4.easeOut',
			y: 200,
			duration: 0.4,
		},
		{
			autoAlpha: 1,
			ease: 'power4.easeOut',
			y: 0,
			duration: 1,
		},
	);
	tl11.to(activeBtn, {
		autoAlpha: 1,
		ease: 'power4.easeOut',
		duration: 0.5,
	});
}
