import { gsap } from 'gsap';

export default async function htmlBgColor(color) {
	const html = document.querySelector('html');
	gsap.to(html, {
		backgroundColor: color,
		ease: 'power4.easeOut',
		transitionDuration: 1,
	});
}
