import { createSlice } from '@reduxjs/toolkit';

export const apiUrlParamsSlice = createSlice({
	name: 'apiUrlParams',
	initialState: {
		apiUrlParams: {
			price_min: '100000',
			price_max: '10000000',
			area_usable_min: '25',
			area_usable_max: '126',
			rooms_count: null,
			floor: '',
		},
		queryParamsString: '',
	},
	reducers: {
		setApiUrlParams: (state, action) => {
			state.apiUrlParams = action.payload;
		},
		setQueryParamsString: (state, action) => {
			state.queryParamsString = action.payload;
		},
		// prepare(key, value) {
		// 	return {
		// 		payload: {
		// 			key,
		// 			value,
		// 		},
		// 	};
		// },
	},
});

export const { setApiUrlParams, setQueryParamsString } = apiUrlParamsSlice.actions;

export default apiUrlParamsSlice.reducer;
