export default async function photoDescriptionAnimation(tl, img, cover) {
	tl.to(img, {
		duration: 0.1,
		opacity: 1,
		ease: 'ease',
	});

	tl.to(cover, {
		duration: 0.3,
		yPercent: 3,
		ease: 'power4',
	});

	tl.to(cover, {
		duration: 0.65,
		yPercent: 100,
		ease: 'ease',
	});
}
