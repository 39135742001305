import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
	color: ${({ theme, $isDarkBg }) => ($isDarkBg ? theme.colors.white : theme.colors.black)};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: ${({ $isUA }) => ($isUA ? '18px' : '14px')};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 20px;
	position: relative;
	text-decoration: none;
`;

const StyledButton = styled.div`
	color: ${({ theme, $isDarkBg }) => ($isDarkBg ? theme.colors.white : theme.colors.black)};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 20px;
	position: relative;
	text-decoration: none;
`;

const AnimatedLink = ({ isDarkBg, title, to, className, onClick, isUA }) =>
	to ? (
		<StyledLink
			to={to}
			$isUA={isUA}
			$isDarkBg={isDarkBg}
			data-cursor="blend-mode-circle"
			onClick={onClick}
			className={`${className} underline-animation`}
		>
			{title}
		</StyledLink>
	) : (
		<StyledButton $isDarkBg={isDarkBg} data-cursor="blend-mode-circle" onClick={onClick} className={`${className} underline-animation`}>
			{title}
		</StyledButton>
	);

export default AnimatedLink;
