import { createSlice } from '@reduxjs/toolkit';

export const apartmentsDataSlice = createSlice({
	name: 'apartmentsData',
	initialState: {
		apartmentsData: [],
	},
	reducers: {
		setApartmentsData: (state, action) => {
			state.apartmentsData = action.payload;
		},
	},
});

export const { setApartmentsData } = apartmentsDataSlice.actions;

export default apartmentsDataSlice.reducer;
