import { createSlice } from '@reduxjs/toolkit';

export const modelSlice = createSlice({
	name: 'model',
	initialState: {
		activeBuilding: '',
		activeFlat: '',
		is3DModelViewActive: true,
		isRightPanelFromListOpened: false,
		isModelLoaded: false,
	},
	reducers: {
		setActiveBuilding: (state, action) => {
			state.activeBuilding = action.payload;
		},
		setActiveFlat: (state, action) => {
			state.activeFlat = action.payload;
		},
		setIs3DModelViewActive: (state, action) => {
			state.is3DModelViewActive = action.payload;
		},
		setIsRightPanelFromListOpened: (state, action) => {
			state.isRightPanelFromListOpened = action.payload;
		},
		setIsModelLoaded: (state, action) => {
			state.isModelLoaded = action.payload;
		},
	},
});

export const { setActiveBuilding, setActiveFlat, setIs3DModelViewActive, setIsRightPanelFromListOpened, setIsModelLoaded } =
	modelSlice.actions;

export default modelSlice.reducer;
