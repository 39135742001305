import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { isMobile, isMobileOnly } from 'react-device-detect';
import GlobalStyle from '../styles/GlobalStyles';
import themeSettings from '../styles/themeSettings';
import Menu from '../components/menu/Menu';
import FooterWrapper from '../components/footer/FooterWrapper';
import gotoAnimate from '../animations/animationsGsap';
import { setIndicatorAnim } from '../state/indicatorAnim';
import { setMenuColor } from '../state/menuColor';
import setInitialColors from '../utils/setInitialColors';
import FlipCover from '../components/flipCover/flipCover';
import CookieInfo from '../components/cookies/cookiesInfo';
import CustomCursor2 from '../components/customCursor/CustomCursor2';

const StyledView = styled.section`
	height: 100%;
	position: relative;
	width: 100vw;
`;

const Layout = ({ children, pageContext, location }) => {
	const [isMobileDevice, setDevice] = useState(true);
	const [isMobileOnlyDevice, setMobileOnlyDevice] = useState(true);

	gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
	const activeLang = (pageContext?.locale === 'uk' ? 'ua' : pageContext?.locale) || '';
	const menuData = pageContext.globals?.[0] || [];
	const cookiesData = pageContext.cookies?.[0] || [];
	const immutablePageId = pageContext?.immutableId || '';
	const dispatch = useDispatch();
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);
	const introStartModule = useSelector((state) => state.introStartModule.introStartModule);

	const indicatorAnim = useSelector((state) => state.indicatorAnim.indicatorAnim);
	const is3DModelViewActive = useSelector((state) => state.model.is3DModelViewActive);
	const blockScrollQuery = (query) => {
		if (query.matches) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = null;
		}
	};

	useEffect(() => {
		setDevice(isMobile);
		setMobileOnlyDevice(isMobileOnly);
		if (typeof window !== undefined) {
			const orientation = window.matchMedia('(orientation: landscape)');
			isMobileOnly && orientation.addEventListener('change', blockScrollQuery);
		}
	}, []);

	useEffect(() => {
		const footer = document.getElementById('footer');
		const last = document.querySelector(['#fullpage', '.last']);
		gsap.set(footer, { yPercent: -75, autoAlpha: '0' });

		const uncover = gsap.timeline({ paused: true });
		uncover.to(footer, { yPercent: 0, ease: 'none', autoAlpha: '1' });

		ScrollTrigger.create({
			id: 'footer',
			trigger: last,
			start: 'bottom bottom',
			end: '+=100%',
			animation: uncover,
			scrub: true,
		});
		return () => {
			const footerST = ScrollTrigger.getById('footer');
			footerST.kill();
		};
	}, [location.pathname]);
	useEffect(() => {
		if (immutablePageId === 'foodstories_id' || immutablePageId === 'noho_id') {
			dispatch(setMenuColor(true));
		} else {
			dispatch(setMenuColor(false));
		}
	}, [location.pathname]);

	useEffect(() => {
		if (immutablePageId !== 'apartments_id') {
			setInitialColors(pageContext?.immutableId, false);
		} else {
			is3DModelViewActive ? setInitialColors(pageContext?.immutableId, true) : setInitialColors(pageContext?.immutableId, false);
			return () => {
				setInitialColors(pageContext?.immutableId, false);
			};
		}
	});

	useLayoutEffect(() => {
		const triggers = [];
		if (
			immutablePageId === 'lifestyle_id' ||
			immutablePageId === 'foodstories_id' ||
			immutablePageId === 'noho_id' ||
			immutablePageId === 'noho_smart_id' ||
			immutablePageId === 'investment_id'
		) {
			const animations = gsap.utils.toArray('.anim');
			animations.forEach((anim, index) => {
				const tempElAttributes = anim?.attributes || [];
				const dataType = tempElAttributes?.['data-animation-type']?.value || null;
				const tr = ScrollTrigger.create({
					trigger: anim,
					start: 'top bottom-=200',
					end: 'bottom bottom',
					id: immutablePageId,
					once: true,
					onEnter: () => {
						gotoAnimate(index, dataType, anim);
					},
				});
				triggers.push(tr);
			});
		}
		return () => {
			triggers.forEach((t) => t.kill());
		};
	});

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const windowHeight = window?.innerHeight || 0;
			if (window.scrollY > 50) {
				dispatch(setIndicatorAnim(true));
			} else if (window.scrollY > windowHeight) {
				dispatch(setIndicatorAnim(false));
			}
		});

		const indicatorBtn = document.getElementById('indicatorBtn');
		const wrapIndicator = document.getElementById('wrapIndicator');
		const scrollIndicator = document.getElementById('scrollIndicator');
		const scrollToElement = document.getElementById('scrollTo');
		const tl = gsap.timeline();

		if (indicatorAnim) {
			gsap.registerPlugin(ScrollTrigger);
			const rotateDuration = 12;
			const clamp = gsap.utils.clamp(-100, 100);

			const rotate = gsap.to(scrollIndicator, {
				rotate: 360,
				duration: rotateDuration,
				repeat: -1,
				ease: 'none',
				onReverseComplete() {
					rotate.totalTime(rotateDuration * 200);
				},
			});
			ScrollTrigger.create({
				onUpdate: (self) => {
					rotate.timeScale(clamp(self.getVelocity() / 200));
					gsap.to(rotate, { timeScale: 1, duration: 1, overwrite: true, ease: 'power1.easeOut' });
				},
			});
		}

		if (indicatorBtn && scrollToElement) {
			indicatorBtn.addEventListener('click', () => {
				if (immutablePageId === 'investment_id') {
					window.fullpage_api.moveTo(2);
					dispatch(setIndicatorAnim(true));
				} else {
					tl.to(wrapIndicator, {
						display: 'none',
						duration: 0.15,
					});
					tl.to(window, {
						duration: 0.75,
						scrollTo: scrollToElement,
						onEnter: () => {
							tl.to(wrapIndicator, {
								display: 'flex',
							});
						},
					});
				}
			});
		}
	});

	return (
		<ThemeProvider theme={themeSettings}>
			<GlobalStyle isDropdownMenuOpen={isDropdownMenuOpen} />
			{!isMobileDevice && <CustomCursor2 />}
			{isMobileOnlyDevice && <FlipCover data={menuData} />}
			<Menu menuData={menuData} activeLang={activeLang} pageContext={pageContext} location={location} className="menu" />
			{immutablePageId !== 'sauna_id' &&
				immutablePageId !== 'coworking_id' &&
				immutablePageId !== 'fitness_id' &&
				immutablePageId !== 'club_id' && <CookieInfo cookiesData={cookiesData} />}
			{children}
			{immutablePageId !== 'apartments_id' &&
				immutablePageId !== 'sauna_id' &&
				immutablePageId !== 'coworking_id' &&
				immutablePageId !== 'fitness_id' &&
				immutablePageId !== 'club_id' && (
					<StyledView style={{ display: 'block' }}>
						{/*<StyledView style={{ display: introStartModule ? 'none' : 'block' }}>*/}
						<FooterWrapper footerData={menuData} activeLang={activeLang} immutablePageId={immutablePageId} />
					</StyledView>
				)}
		</ThemeProvider>
	);
};

export default Layout;
