import React from 'react';
import styled, { css } from 'styled-components';
import FacebookInlineSvg from '../../assets/globals/facebook-logo.inline.svg';
import InstagramInlineSvg from '../../assets/globals/instagram-logo.inline.svg';
import LinkedInInlineSvg from '../../assets/globals/linked-logo.inline.svg';
import PinterestInlineSvg from '../../assets/globals/pinterest-logo.inline.svg';

const StyledSocialMediaWrapper = styled.div`
	display: flex;
	margin-top: 40px;
	gap: 20px;
	a {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 24px;
		svg {
			margin-right: 0;
		}
		svg path {
			transition: fill 0.3s ease-in-out;
		}
		&:hover {
			svg path {
				fill: ${({ theme }) => theme.colors.lightBrown};
			}
		}
	}

	@media (min-width: 1024px) {
		margin-top: 40px;
	}
	${({ inFooter }) =>
		inFooter &&
		css`
			svg > path {
				fill: ${({ theme }) => theme.colors.grey};
			}
			margin-top: 0px;
			@media (min-width: 1024px) {
				margin-top: 3vh;
			}
		`}
	${({ inContact }) =>
		inContact &&
		css`
			margin-top: 0px;
			@media (min-width: 1024px) {
				margin-top: 0px;
			}
			a {
				svg path {
					fill: ${({ theme }) => theme.colors.black};
				}
			}
		`}
`;

const SocialMediaIcons = ({ data, inFooter, inContact }) => {
	const socialMediaFbLink = data?.socialMediaFacebookLink || '';
	const socialMediaInstagramLink = data?.socialMediaInstagramLink || '';
	const socialMediaLinkedInLink = data?.socialMediaLinkedinLink || '';
	const socialMediaPinterestLink = data?.socialMediaPinterestLink || '';
	return (
		<StyledSocialMediaWrapper inFooter={inFooter} inContact={inContact}>
			<a href={socialMediaFbLink} target="_blank" rel="noreferrer" data-cursor="hidden-mode">
				<FacebookInlineSvg />
			</a>
			<a href={socialMediaInstagramLink} target="_blank" rel="noreferrer" data-cursor="hidden-mode">
				<InstagramInlineSvg />
			</a>
			<a href={socialMediaLinkedInLink} target="_blank" rel="noreferrer" data-cursor="hidden-mode">
				<LinkedInInlineSvg />
			</a>
			<a href={socialMediaPinterestLink} target="_blank" rel="noreferrer" data-cursor="hidden-mode">
				<PinterestInlineSvg />
			</a>
		</StyledSocialMediaWrapper>
	);
};

export default SocialMediaIcons;
