exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-apartments-page-js": () => import("./../../../src/templates/apartmentsPage.js" /* webpackChunkName: "component---src-templates-apartments-page-js" */),
  "component---src-templates-club-room-page-js": () => import("./../../../src/templates/clubRoomPage.js" /* webpackChunkName: "component---src-templates-club-room-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-coworking-page-js": () => import("./../../../src/templates/coworkingPage.js" /* webpackChunkName: "component---src-templates-coworking-page-js" */),
  "component---src-templates-fitness-page-js": () => import("./../../../src/templates/fitnessPage.js" /* webpackChunkName: "component---src-templates-fitness-page-js" */),
  "component---src-templates-foodstories-page-js": () => import("./../../../src/templates/foodstoriesPage.js" /* webpackChunkName: "component---src-templates-foodstories-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-investment-page-js": () => import("./../../../src/templates/investmentPage.js" /* webpackChunkName: "component---src-templates-investment-page-js" */),
  "component---src-templates-lifestyle-page-js": () => import("./../../../src/templates/lifestylePage.js" /* webpackChunkName: "component---src-templates-lifestyle-page-js" */),
  "component---src-templates-noho-page-js": () => import("./../../../src/templates/nohoPage.js" /* webpackChunkName: "component---src-templates-noho-page-js" */),
  "component---src-templates-noho-smart-page-js": () => import("./../../../src/templates/nohoSmartPage.js" /* webpackChunkName: "component---src-templates-noho-smart-page-js" */),
  "component---src-templates-policy-privacy-page-js": () => import("./../../../src/templates/policyPrivacyPage.js" /* webpackChunkName: "component---src-templates-policy-privacy-page-js" */),
  "component---src-templates-sauna-page-js": () => import("./../../../src/templates/saunaPage.js" /* webpackChunkName: "component---src-templates-sauna-page-js" */)
}

