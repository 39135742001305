import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import getSlug from '../../utils/getSlug';
import { setIsDropdownMenuOpen, setTopMovement } from '../../state/isDropdownMenuOpenSlice';
import SocialMediaIcons from '../socialMediaIcons/SocialMediaIcons';
import ChangeLangSelector from './ChangeLangSelector';

const StyledDropdownWithIcon = styled.div`
	height: auto;
	left: 0;
	position: fixed;
	top: 0;
	user-select: none;
	width: 100%;
	z-index: 106;
`;

const StyledMenuIconWrapper = styled.div`
	//cursor: pointer;
	align-items: center;
	display: ${({ scaleIntroAnimation }) => (scaleIntroAnimation ? 'flex' : 'none')};
	height: 48px;
	justify-content: center;
	position: absolute;
	right: 25px;
	top: 24px;
	width: 48px;
	z-index: 13;
	filter: contrast(0.3);
	//filter: invert(0.5);
	// background-color: ${({ theme }) => theme.colors.white};

	@media (min-width: 768px) {
		top: 48px;
		right: 48px;
		&:hover {
			filter: none;

			div {
				background-color: ${({ theme }) => theme.colors.lightBrown};

				&:before,
				&:after {
					background-color: ${({ theme }) => theme.colors.lightBrown};
				}

				&:before {
					top: -6px;
				}

				&:after {
					top: 6px;
				}
			}
		}
	}
	//@media (min-width: 1024px) {
	//	top: 40px;
	//	right: 40px;
	//}
`;

const StyledDropdownWrapperOuter = styled.div`
	//height: 0;
	//height: 100%;
	//min-height: 100vh;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	height: var(--inner-height);
	max-height: var(--inner-height);
	opacity: 0;
	overflow: auto;
	position: fixed;
	transform: translateY(-100vh);
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	width: 100vw;
	z-index: 12;

	@media (min-width: 1024px) and (min-height: 900px) {
		overflow: hidden;
	}

	&.active {
		opacity: 1;
		transform: translateY(0);
	}
`;

const StyledDropdownWrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 24px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	left: 0;
	line-height: 36px;
	margin: 35px 25px 10px;
	width: 100%;

	@media (min-width: 768px) {
		font-size: 40px;
		line-height: 50px;
		margin: 50px 65px 10px;
	}
	@media (min-width: 1024px) {
		flex-direction: row;
		font-size: 40px;
		line-height: 50px;
		margin: 62px 65px 50px;
		position: relative;
		min-height: 600px;
	}
`;

const StyledMenuItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		width: 50%;
		justify-content: space-between;
		margin-top: 0;
	}
`;

const StyledDropdownItem = styled(Link)`
	//opacity: 0;
	//transform: translateY(50px);
	//transition: transform 0.25s 0.5s, opacity 0.25s 0.5s;
	align-items: center;
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	height: 100%;
	opacity: 0;
	padding-bottom: 30px;
	transform: translate3d(0, 50px, 0);
	transition: opacity 0.2s;
	&:last-of-type {
		padding-bottom: 0;
	}

	&.animate {
		//transition-delay: 0.25s;
		//transition-delay: calc(${({ $animationDelay }) => $animationDelay} * 0.1s + 0.25s);
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: transform 0.5s ease-out, opacity 0.5s ease-out;
		transition-delay: 0.35s;
	}

	&.active {
		color: ${({ theme }) => theme.colors.graphite};
	}

	@media (min-width: 768px) {
		padding-bottom: 40px;
		&:focus,
		&:hover {
			color: ${({ theme }) => theme.colors.lightBrown};
		}
	}
	@media (min-width: 1024px) {
		padding-bottom: 0;
	}
`;

const StyledAllInfoWrapper = styled.div`
	opacity: 0;
	transform: translate3d(0, 100px, 0);

	&.animate {
		//transition: transform 0.25s ease-out, opacity 0.25s ease-out;
		//transition-delay: 0.25s;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: transform 0.5s ease-out, opacity 0.5s ease-out;
		transition-delay: 0.35s;
	}

	@media (min-width: 1024px) {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		user-select: text;
	}
`;

const StyledInfoWrapper = styled.div`
	color: ${({ theme }) => theme.colors.grey};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 14px;
	line-height: 30px;
	margin-top: 40px;

	a {
		cursor: pointer;
	}

	.line {
		background-color: ${({ theme }) => theme.colors.grey};
		height: 2px;
		margin: 15px 0;
		width: 40px;
	}

	@media (min-width: 768px) {
		margin-top: 65px;
	}

	@media (min-width: 1024px) {
		margin-top: 110px;
		div {
			text-align: end;
		}

		.line {
			margin-right: 0;
			margin-left: auto;
			width: 40px;
		}
	}
`;

const StyledLangChangeWrapper = styled.div`
	color: ${({ theme }) => theme.colors.grey};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 24px;
	font-weight: ${({ theme }) => theme.fontWeight.extraLight};
	line-height: 20px;
	margin: 42px 0;
	@media (min-width: 1024px) {
		margin-top: 50px;
	}
`;

const StyledNumberWrapper = styled.div`
	display: none;
	@media (min-width: 1024px) {
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 230px;
		line-height: 200px;
		height: 200px;
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-weight: ${({ theme }) => theme.fontWeight.thin};
		color: ${({ theme }) => theme.colors.greyAlpha};
		width: 267px;
		justify-content: space-between;
		overflow: hidden;
	}
`;

const StyledNumberContainer = styled.div`
	text-align: center;
	width: 50%;

	&.animate {
		animation: numberAnimate 0.2s cubic-bezier(0.31, 0.13, 0.11, 1) forwards;
		transform: translateY(-100%);
	}
	&.reset {
		transform: translateY(0);
	}

	@keyframes numberAnimate {
		0% {
			transform: translateY(0%);
		}
		100% {
			transform: translateY(-100%);
		}
	}
`;

export const MenuImg = styled.div`
  position: relative;
    //background-color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  height: 2px;
  left: 0;
  top: 0;
  transition: transform 0.25s;
  width: 26px;
  &:before,
  &:after {
    background-color: ${({ theme }) => theme.colors.white};
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transition: transform 0.25s, top 0.25s;
    width: 26px;
  }
  &:before {
    top: -8px;
  }
  &:after {
    top: 8px;
  }
  &.active {
    background-color: transparent !important;
    transform: rotate(90deg);

    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.white} !important;
      top: 0 !important;
      transition: background-color 1.2s;
    }
    &:before {
	  transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
      
    }
  }

  &.on-panel {
    &.active {
      &:before,
      &:after {
        background-color: ${({ theme }) => theme.colors.grey} !important;
      }
    }
`;

const DropdownMenu = ({
	menuData,
	activePage,
	activeLang,
	menuKeyValueObject,
	handleMenuItemClick,
	otherPagesPathsForLangChange,
	immutablePageId,
}) => {
	const [activeNumber, setActiveNumber] = useState(activePage);
	const [previousActiveForAnimation, setPreviousActiveForAnimation] = useState(activePage);
	const scaleIntroAnimation = useSelector((state) => state.scaleIntroAnimation.scaleIntroAnimation);
	const menuTopItems = menuData?.menuTopItems || [];
	const generalInfoPhoneNumber = menuData?.generalInfoPhoneNumber || '';
	const generalInfoEmail = menuData?.generalInfoEmail || '';
	const generalInfoAddress = menuData?.generalInfoAddress || '';
	const dispatch = useDispatch();
	const isDropdownMenu = useSelector((state) => state.isDropdownMenu.isDropdownMenu);
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);
	const isAskAboutPricePopupOpen = useSelector((state) => state.isAskAboutPricePopupOpen.isAskAboutPricePopupOpen);
	const activeBuilding = useSelector((state) => state.model.activeBuilding);
	const isRightPanelFromMenuOpened = useSelector((state) => state.model.isRightPanelFromListOpened);
	const menuDropdownRef = useRef();
	const menuCollapseAnimation = useRef(null);

	const toggleDropdown = () => {
		const offsetTop = window.scrollY;

		immutablePageId !== 'investment_id' && dispatch(setTopMovement(offsetTop));
		dispatch(setIsDropdownMenuOpen(!isDropdownMenuOpen));
	};

	const handleDropdownItemClick = (activeMenuItemNumber) => {
		handleMenuItemClick(activeMenuItemNumber);
		toggleDropdown();
	};

	const handleMenuItemsLeave = () => {
		if (activeNumber !== activePage) {
			setActiveNumber(activePage);
		}
	};

	const handleItemHover = (activeMenuItemNumber) => {
		setActiveNumber(activeMenuItemNumber);
	};

	// useEffect(() => {
	// 	const menuDropdownRefInstance = menuDropdownRef.current;
	//
	// 	let docStyle = getComputedStyle(document.documentElement);
	// 	let tempHeight = docStyle.getPropertyValue('--inner-height');
	// 	menuCollapseAnimation.current = gsap.timeline();
	// 	menuCollapseAnimation.current.call(() => {
	// 		docStyle = getComputedStyle(document.documentElement);
	// 		tempHeight = docStyle.getPropertyValue('--inner-height');
	// 	});
	// 	menuCollapseAnimation.current.to(menuDropdownRefInstance, {
	// 		duration: 0.5,
	// 		opacity: 1,
	// 		// height: '100vh',
	// 		// height: 'var(--inner-height)',
	// 		// height: tempHeight,
	// 		// height: (i, document) => gsap.getProperty(document, '--height'),
	// 		height: tempHeight,
	// 		ease: 'expo.inOut',
	// 	});
	// 	menuCollapseAnimation.current.reverse();
	//
	// 	return () => {
	// 		menuCollapseAnimation.current.kill();
	// 	};
	// }, []);

	useEffect(() => {
		if (activeNumber !== activePage) {
			setActiveNumber(activePage);
		}
		if (previousActiveForAnimation !== activePage) {
			setPreviousActiveForAnimation(activePage);
		}
	}, [activePage]);

	useEffect(() => {
		if (immutablePageId !== 'investment_id') {
			if (isDropdownMenuOpen && menuDropdownRef) {
				menuDropdownRef.current.scrollTop = 0;
			}
			if (isDropdownMenuOpen) {
				// disable body scroll when menu open
				const offsetTop = window.scrollY;
				document.body.style.position = 'fixed';
				document.body.style.top = `-${offsetTop}px`;
			} else {
				const scrollY = document.body.style.top;
				document.body.style.position = '';
				document.body.style.top = '';
				window.scrollTo(0, parseInt(scrollY || '0') * -1);
			}
		} else if (isDropdownMenuOpen) document.querySelector('body').style.overflow = 'hidden';
		else document.querySelector('body').style.overflow = 'visible';
	}, [isDropdownMenuOpen]);

	useEffect(() => {
		if (isDropdownMenuOpen) {
			const timer = setTimeout(() => {
				setPreviousActiveForAnimation(activeNumber);
			}, 200);
			return () => clearTimeout(timer);
		}
	}, [activeNumber]);

	return (
		<StyledDropdownWithIcon>
			{isDropdownMenu && !isAskAboutPricePopupOpen && activeBuilding === '' && !isRightPanelFromMenuOpened && (
				<StyledMenuIconWrapper
					id="menu-icon-wrapper"
					onClick={toggleDropdown}
					className={isDropdownMenuOpen ? 'active' : ''}
					scaleIntroAnimation={scaleIntroAnimation}
					data-cursor="blend-mode-circle"
				>
					<MenuImg className={isDropdownMenuOpen ? 'active' : ''} />
				</StyledMenuIconWrapper>
			)}
			<StyledDropdownWrapperOuter ref={menuDropdownRef} className={isDropdownMenuOpen ? 'active' : ''}>
				<StyledDropdownWrapperInner>
					<StyledMenuItemsWrapper onMouseLeave={() => handleMenuItemsLeave()}>
						{menuTopItems.map((item, key) => {
							const slug = item?.menuItemLink?.slug || '';

							return (
								<StyledDropdownItem
									// className={activePage === menuKeyValueObject[item.immutableId] ? 'active' : ''}
									className={`${isDropdownMenuOpen && 'animate'} ${
										activePage === menuKeyValueObject[item.immutableId] && 'active'
									}`}
									$animationDelay={key}
									key={item.originalId}
									to={`/${getSlug(slug, activeLang)}`}
									onClick={() => handleDropdownItemClick(menuKeyValueObject[item.immutableId])}
									onMouseEnter={() => (isMobile ? () => false : handleItemHover(menuKeyValueObject[item.immutableId]))}
								>
									<div data-cursor="blend-mode-circle">{item.menuItemName}</div>
								</StyledDropdownItem>
							);
						})}
					</StyledMenuItemsWrapper>
					<StyledAllInfoWrapper className={`${isDropdownMenuOpen && 'animate'}`}>
						<StyledInfoWrapper>
							<div>
								<a href={`tel:${generalInfoPhoneNumber}`} data-cursor="hidden-mode">
									<span className="footer-info">{generalInfoPhoneNumber}</span>
								</a>
							</div>
							<div>
								<a href={`mailto:${generalInfoEmail}`} data-cursor="hidden-mode">
									<span className="footer-info">{generalInfoEmail}</span>
								</a>
							</div>
							<div className="line" />
							<div>{generalInfoAddress}</div>
						</StyledInfoWrapper>
						<SocialMediaIcons data={menuData} />
						<StyledLangChangeWrapper>
							{/* temporary lang change element */}
							{/* pl en uk */}
							<ChangeLangSelector
								activeLang={activeLang}
								otherPagesPathsForLangChange={otherPagesPathsForLangChange}
								onDropdownMenu
								immutablePageId={immutablePageId}
							/>
						</StyledLangChangeWrapper>
					</StyledAllInfoWrapper>
					<StyledNumberWrapper>
						<div>0</div>
						<StyledNumberContainer className={activeNumber !== previousActiveForAnimation ? 'animate' : 'reset'}>
							<div>{previousActiveForAnimation}</div>
							<div>{activeNumber}</div>
						</StyledNumberContainer>
					</StyledNumberWrapper>
				</StyledDropdownWrapperInner>
			</StyledDropdownWrapperOuter>
		</StyledDropdownWithIcon>
	);
};

export default DropdownMenu;
