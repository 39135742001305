import React from 'react';
import styled from 'styled-components';
import LogoSvg from '/src/assets/logo/logo.inline.svg';
import RotateScreen from '/src/assets/globals/icon_rotate-screen.inline.svg';

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: none;
	grid-template-columns: 1fr 2.5fr;
	height: 100vh;
	position: fixed;
	width: 100vw;
	z-index: 100000;

	@media (orientation: landscape) {
		@media (max-height: 640px) {
			display: grid;
		}
	}
`;

const StyledLogoWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;
	svg {
		height: 50%;
		width: 50%;
	}
	&:after {
		border-bottom: 1px solid ${({ theme }) => theme.colors.lightBrown};
		content: '';
		height: 1px;
		position: absolute;
		right: -50%;
		transform: rotate(90deg);
		width: 100%;
	}
`;

const StyledTextWrapper = styled.div`
	align-items: start;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	padding-left: 50px;
	h1 {
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-size: 56px;
		font-weight: ${({ theme }) => theme.fontWeight.light};
		line-height: 72px;
		margin-top: 8px;
	}
	h2 {
		color: ${({ theme }) => theme.colors.grey};
		font-family: ${({ theme }) => theme.fontFamily.primaryItalic};
		font-size: 32px;
		line-height: 50px;
	}
`;

const FlipCover = ({ data }) => {
	const bigHeader = data.flipCoverBigHeader || '';
	const smallHeader = data.flipCoverSmallHeader || '';
	return (
		<StyledWrapper>
			<StyledLogoWrapper>
				<LogoSvg />
			</StyledLogoWrapper>
			<StyledTextWrapper>
				<RotateScreen />
				<h1>{bigHeader}</h1>
				<h2>{smallHeader}</h2>
			</StyledTextWrapper>
		</StyledWrapper>
	);
};
export default FlipCover;
