import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PageContainer from '../../common/PageContainer';
import LogoSvg from '../../assets/logo/logo-gray.svg';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import SocialMediaIcons from '../socialMediaIcons/SocialMediaIcons';
import ArrowButton from '../../common/ArrowButton';
import getSlug from '../../utils/getSlug';
import LogoSVG from '../../assets/logo/PLEO_digital.inline.svg';
import NohoSVG from '../../assets/logo/noho_investment_logo.inline.svg';
import {useDispatch} from "react-redux";
import {setShowModalNoMatterWhat} from "../../state/necessaryCookiesSlice";

const StyledFooterWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	bottom: 0;
	display: none;
	min-height: 100vh;
	padding-top: 5%;
	position: sticky;
	width: 100%;
	z-index: 0;
	@media (min-width: 768px) and (max-width: 1365px) {
		display: inline-block;
	}
`;

const StyledFooterContainerTop = styled.div`
	border-bottom: solid 0.1em ${({ theme }) => theme.colors.grey};
	padding: 84px 0;
	width: 100%;

	> :nth-child(2n) {
		padding-top: 58px;
	}
`;

const StyledFooterContainerBottom = styled.div`
	width: 100%;

	> :nth-child(2) {
		align-items: start;
	}
	> div {
		padding-top: 48px;
	}
`;

const StyledFooterContainerRow = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	:first-child {
		a {
			display: flex;
			flex-direction: column;
		}
	}
	&.column {
		flex-direction: column;
		align-items: start;
		row-gap: 32px;
		margin-top: 0;
		> div {
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			color: ${({ theme }) => theme.colors.grey};
		}
		#logo_noho {
			width: 160px;
		}
	}
`;

const StyledFooterContainerItem = styled.div`
	color: ${({ theme }) => theme.colors.gray};

	:nth-of-type(2) {
		flex-direction: column;
		display: flex;
		flex-wrap: wrap;
		align-items: end;
	}
	width: 50%;
	a:nth-of-type(2) {
		span {
			margin-top: 32px;
		}
	}
	svg {
		margin-right: 1vw;
	}
	:last-of-type {
		width: 476px;
	}
	a {
		width: fit-content;
	}
`;

const StyledAuthorInfo = styled.div`
	display: flex;
	justify-content: start;
	margin-top: 90px;
	padding-bottom: 15px;
	a {
		display: flex;
		align-items: center;
		height: 100%;
		color: ${({ theme }) => theme.colors.grey} !important;
		font-size: ${({ isUA }) => (isUA ? '17px' : '14px')} !important;
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		font-family: ${({ theme }) => theme.fontFamily.secondary};
	}
	svg {
		margin-left: 6px;
		path {
			fill: ${({ theme }) => theme.colors.grey};
		}
	}
`;

const FooterTablet = ({ footerData, activeLang, immutablePageId }) => {
	const isUA = activeLang === 'ua';
	const header = footerData?.footerHeader || '';
	const buttonText = footerData?.footerButtonText || '';
	const buttonLink = footerData?.footerButtonLink?.[0]?.slug || '';
	const phoneNumber = footerData?.generalInfoPhoneNumber || '';
	const email = footerData?.generalInfoEmail || '';
	const investor = footerData?.footerInvestor || '';
	const privacyPolicy = footerData?.footerPrivacyPolicy || '';
	const privacyPolicyLink = footerData?.footerPrivacyPolicyLink?.slug || '';
	const address = footerData?.generalInfoAddress || '';
	const disclaimer = footerData?.footerDisclaimer || '';
	const description = footerData?.footerDescription || '';
	const manageCookiesText = activeLang === 'pl' ? 'Zarządzaj plikami cookie' : activeLang === 'ua' ? 'Керувати файлами cookie': 'Manage cookies';
	const dispatch = useDispatch();
	const handleCookiesModal = () => {
		dispatch(setShowModalNoMatterWhat(true))
	}
	return (
		<StyledFooterWrapper>
			<PageContainer id="footer" className="content-standard">
				<StyledFooterContainerTop>
					<StyledFooterContainerRow className={'column'}>
						{/*<span className="footer-header-text">{header}</span>*/}
						<NohoSVG id="logo_noho" />
						<div dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)} />
					</StyledFooterContainerRow>

					<StyledFooterContainerRow>
						<Link className="contact" to={`/${getSlug(buttonLink, activeLang)}`}>
							{buttonText}
						</Link>
						<ArrowButton to={`/${getSlug(buttonLink, activeLang)}`} />
					</StyledFooterContainerRow>
				</StyledFooterContainerTop>

				<StyledFooterContainerBottom>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							{immutablePageId !== 'home_id' ? (
								<Link to={`/${getSlug('', activeLang)}`}>
									<img src={LogoSvg} alt="Dolnych Młynów 10" />
								</Link>
							) : (
								<img
									src={LogoSvg}
									onClick={(e) => {
										window.scrollTo({
											top: 0,
											behavior: 'smooth',
										});
									}}
									alt="Dolnych Młynów 10"
								/>
							)}
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<a href={`tel:${phoneNumber}`}>
								<span className="footer-info">{phoneNumber}</span>
							</a>
							<a href={`mailto:${email}`}>
								<span className="footer-info">{email}</span>
							</a>
						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							<a href="https://www.nohoinvestment.com/" data-cursor="hidden-mode">
								<span className="footer-info">{investor}</span>
							</a>
						</StyledFooterContainerItem>
						<StyledFooterContainerItem>
							<Link to={`/${getSlug(privacyPolicyLink, activeLang)}`}>
								<span className="footer-info">{privacyPolicy}</span>
							</Link> <br/>
							<span data-cursor="hidden-mode" onClick={() => handleCookiesModal()}
								  className="footer-info open-cookies">{manageCookiesText}</span>

						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							<SocialMediaIcons inFooter data={footerData} />
						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
					<StyledFooterContainerRow>
						<StyledFooterContainerItem>
							<span className="footer-info address">{address}</span>
							<div className="inner-html" dangerouslySetInnerHTML={GetHtmlFromRedactorField(disclaimer)} />
						</StyledFooterContainerItem>
					</StyledFooterContainerRow>
				</StyledFooterContainerBottom>
				<StyledAuthorInfo isUA={isUA}>
					<a href="https://pleodigital.com/" target="_blank" rel="nofollow" data-cursor="blend-mode-circle">
						developed by
						<LogoSVG />
					</a>
				</StyledAuthorInfo>
			</PageContainer>
		</StyledFooterWrapper>
	);
};

export default FooterTablet;
