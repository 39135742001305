import { createSlice } from '@reduxjs/toolkit';

export const scaleIntroAnimation = createSlice({
	name: 'scaleIntroAnimation',
	initialState: {
		scaleIntroAnimation: true,
	},
	reducers: {
		setScaleIntroAnimation: (state, action) => {
			state.scaleIntroAnimation = action.payload;
		},
	},
});

export const { setScaleIntroAnimation } = scaleIntroAnimation.actions;

export default scaleIntroAnimation.reducer;
