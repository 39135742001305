import gsap from 'gsap';

export default async function textAnimation(text) {
	gsap.fromTo(
		text,
		{
			autoAlpha: 0,
			ease: 'power4.easeOut',
			y: 200,
			duration: 0.4,
		},
		{
			autoAlpha: 1,
			ease: 'power4.easeOut',
			y: 0,
			duration: 1,
		},
	);
}
