import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice';
import isDropdownMenuOpenReducer from './isDropdownMenuOpenSlice';
import isDropdownMenuReducer from './isDropdownMenuSlice';
import apiUrlParamsReducer from './apiUrlParamsSlice';
import modelReducer from './modelSlice';
import apartmentsDataReducer from './apartmentsDataSlice';
import isAskAboutPricePopupOpenReducer from './askAboutPricePopupSlice';
import scaleIntroAnimationReducer from './scaleIntroAnim';
import indicatorAnimReducer from './indicatorAnim';
import introStartModuleReducer from './introStartModule';
import menuColorReducer from './menuColor';
import introLoadedOnceReducer from './introLoadedOnce';
import necessaryCookiesReducer from './necessaryCookiesSlice';

export default configureStore({
	reducer: {
		language: languageReducer,
		isDropdownMenuOpen: isDropdownMenuOpenReducer,
		isDropdownMenu: isDropdownMenuReducer,
		apiUrlParams: apiUrlParamsReducer,
		model: modelReducer,
		apartmentsData: apartmentsDataReducer,
		isAskAboutPricePopupOpen: isAskAboutPricePopupOpenReducer,
		scaleIntroAnimation: scaleIntroAnimationReducer,
		indicatorAnim: indicatorAnimReducer,
		introStartModule: introStartModuleReducer,
		menuColor: menuColorReducer,
		introLoadedOnce: introLoadedOnceReducer,
		necessaryCookies: necessaryCookiesReducer,
	},
});
