import { createSlice } from '@reduxjs/toolkit';

export const introStartModule = createSlice({
	name: 'introStartModule',
	initialState: {
		introStartModule: false,
	},
	reducers: {
		setIntroStartModule: (state, action) => {
			state.introStartModule = action.payload;
		},
	},
});

export const { setIntroStartModule } = introStartModule.actions;

export default introStartModule.reducer;
