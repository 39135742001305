import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import JSCookie from 'js-cookie';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import AnimatedLink from '../../common/AnimatedLink';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import { setNecessaryCookies, setShowModalNoMatterWhat } from '../../state/necessaryCookiesSlice';

//allows for object setting
const Cookies = JSCookie.withConverter({
	write: function (value) {
		return JSON.stringify(value);
	},
});

const StyledButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;
	margin-bottom: 5px;
	${({ config }) =>
		config &&
		css`
			@media (max-width: 765px) {
				align-items: end;
			}
		`}
`;

const StyledCookiesExtendedWrapper = styled.div`
	animation: showUp 1s ease-in-out;
	background-color: ${({ theme }) => theme.colors.creamyLight};
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 12px;
	line-height: 16px;
	padding: 15px;
	position: fixed;
	row-gap: 20px;
	transition: all 0.35s ease-in-out;
	z-index: 149;
	max-height: 80%;
	@keyframes showUp {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
	@media (min-width: 766px) {
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		padding: 48px 92px;
		width: 100%;
		max-width: 720px;
		row-gap: 50px;
	}
	@media (max-width: 765px) {
		margin: 15px;
		top: 45%;
		transform: translateY(-50%);
		height: 70vh;
	}
	@media (max-width: 1365px) {
		z-index: 149;
	}
`;

const StyledHeadersWrapper = styled.div`
	column-gap: 40px;
	display: flex;
	flex-direction: row;

	h1 {
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-weight: ${({ theme }) => theme.fontWeight.light};
		line-height: 50px;

		opacity: 0.5;
		position: relative;
		transition: opacity 0.2s ease-in-out;
		&.active {
			opacity: 1;
		}
		@media (min-width: 766px) {
			font-size: 40px;
			margin-bottom: 20px;
		}
	}
`;

const StyledCookiesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 500px;
	overflow-y: scroll;
	padding: 5px 10px;
	row-gap: 25px;
	width: 100%;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	@media (max-width: 765px) {
		max-height: 100%;
	}
`;

const StyledCookie = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	row-gap: 16px;
	h2 {
		font-size: 20px;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		line-height: 30px;
		margin-bottom: 15px;
	}
	p,
	div {
		font-size: 14px;
		line-height: 20px;
	}
	a {
		color: ${({ theme }) => theme.colors.gold};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
		text-decoration: none;
	}
`;

const StyledParagraph = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	line-height: 24px;
	@media (max-width: 765px) {
		padding: 5px 10px;
		height: 100%;
	}
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
	overflow: auto;
`;

const StyledParagraphAndSwitchWrapper = styled.div`
	align-items: start;
	column-gap: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	label {
		height: 32px;
		position: relative;
		width: 60px;
		&.switch {
			margin-top: 15px;
		}
		&#necessary {
			> .slider {
				cursor: not-allowed;
				input {
					pointer-events: none;
				}
			}
		}
		input {
			height: 32px;
			opacity: 0;
			visibility: hidden;
			width: 62px;
		}
	}

	.slider {
		-webkit-transition: 0.4s;
		background-color: ${({ theme }) => theme.colors.white};
		bottom: 0;
		cursor: none;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: 0.4s;
		width: 60px;
	}
	.slider:before {
		-webkit-transition: 0.4s;
		background-color: ${({ theme }) => theme.colors.creamy};
		bottom: 4px;
		content: '';
		height: 24px;
		left: 4px;
		position: absolute;
		transition: 0.4s;
		width: 24px;
	}
	input:checked + .slider {
		background-color: ${({ theme }) => theme.colors.gold};
	}

	input:checked + .slider:before {
		-ms-transform: translateX(26px);
		-webkit-transform: translateX(26px);
		background-color: ${({ theme }) => theme.colors.white};
		transform: translateX(26px);
	}
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;

function formatCookieName(name) {
	return name.slice(2).toLowerCase();
}
function setGTMState(state) {
	return state ? 'granted' : 'denied';
}

function gtag() {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(arguments);
}

class CookieInfo extends Component {
	state = {
		showCookieExtendedInfo: false,
		showCookieExtendedSettings: false,
		cookiesSettings: {
			necessary: true,
			analytics: false,
			marketing: false,
			functional: false,
		},
	};
	disableScroll = (e) => {
		if (!this.body) return;
		this.body.style.overflow = 'hidden';
		this.body.style.touchAction = 'none';
	};
	enableScroll = (e) => {
		if (!this.body) return;
		this.body.style.overflow = '';
		this.body.style.touchAction = '';
	};
	componentDidMount() {
		const { cookiesSettings } = this.state;
		const { cookiesData } = this.props;
		const cookiesArray = cookiesData?.cookiesExtendedInfoConfigCookie || [];
		const cookieParams = cookiesArray.reduce((acc, value) => ({ [formatCookieName(value.cookiesId)]: false, ...acc }), {});
		this.prevCookies = Cookies.get('cookies');
		if (Boolean(this.prevCookies)) {
			const parsed = JSON.parse(this.prevCookies);
			Object.keys(parsed).forEach((key) => {
				cookieParams[key] = parsed[key];
			});
		}
		const finalSettings = { ...cookiesSettings, ...cookieParams };
		this.setState({ cookiesSettings: finalSettings });
		this.setState({ showCookieExtendedInfo: !Boolean(this.prevCookies) });
		this.body = document.querySelector('body');
		// this.body.addEventListener('wheel', this.disableScroll);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.state.showCookieExtendedInfo) {
			this.enableScroll();
		}
	}

	componentWillUnmount() {
		this.body.style.overflow = '';
	}

	setGTMConsent(consent) {
		gtag('consent', 'update', consent);
	}

	setCookies = () => {
		const { dispatch } = this.props;
		const { necessary, analytics, marketing, functional } = this.state.cookiesSettings;
		const expires = 100;
		const cookies = {
			necessary,
			analytics,
			marketing,
			functional,
		};
		const gtm_consent = {
			ad_storage: setGTMState(marketing),
			analytics_storage: setGTMState(analytics),
			ad_user_data: setGTMState(marketing),
			ad_personalization: setGTMState(marketing),
			functionality_storage: setGTMState(functional),
		};
		Cookies.set('cookies', cookies, { expires });
		Cookies.set('pixel', marketing, { expires });
		Cookies.set('gtm', gtm_consent, { expires });
		Cookies.set('gtm-enabled', true, { expires });
		// if (process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'staging') {
		// 	this.setGTMConsent(gtm_consent);
		// }
		this.setGTMConsent(gtm_consent);
		dispatch(setNecessaryCookies(true));
		dispatch(setShowModalNoMatterWhat(false));
		const location = window.location.href;
		initializeAndTrack(location);
	};

	render() {
		const { showCookieExtendedInfo, showCookieExtendedSettings, cookiesSettings } = this.state;
		const { cookiesData, introStartModule, showModalNoMatterWhat } = this.props;
		const discardButtonText = cookiesData?.cookiesExtendedInfoDiscardButtonText || '';
		const configHeader = cookiesData?.cookiesExtendedInfoConfigHeader || '';
		const necessaryDescription = cookiesData?.cookiesExtendedInfoEssentialDescription || '';
		const necessaryHeader = cookiesData?.cookiesExtendedInfoEssentialHeader || '';
		const generalDescription = cookiesData?.cookiesExtendedInfoGeneralDescription || '';
		const generalHeader = cookiesData?.cookiesExtendedInfoGeneralHeader || '';
		const saveButtonText = cookiesData?.cookiesExtendedInfoSaveButtonText || '';
		const saveAllButtonText = cookiesData?.cookiesMainInfoSaveAllButtonText || '';
		const cookiesArray = cookiesData?.cookiesExtendedInfoConfigCookie || [];

		const toggleModal = (toggleStateName) => {
			switch (toggleStateName) {
				case 'showCookieExtendedSettings':
					this.setState((prevState) => ({
						showCookieExtendedSettings: !prevState.showCookieExtendedSettings,
					}));
					break;
				default:
					break;
			}
		};

		const switchCookie = (propertyName, value) => {
			this.setState((prevState) => ({
				cookiesSettings: {
					...prevState.cookiesSettings,
					[propertyName]: value,
				},
			}));
		};

		const handleAcceptAll = async () => {
			const { dispatch } = this.props;
			this.setState({ showCookieExtendedInfo: false });
			dispatch(setShowModalNoMatterWhat(false));
			const acceptState = Object.keys(cookiesSettings).reduce((acc, value) => ({ [value]: true, ...acc }), {});
			await this.setState({
				cookiesSettings: acceptState,
			});

			this.setCookies();
		};

		const handleAccept = () => {
			const { dispatch } = this.props;
			this.setState({ showCookieExtendedInfo: false });
			dispatch(setShowModalNoMatterWhat(false));
			this.setCookies();
		};
		const handleConfig = () => {
			if (showCookieExtendedSettings) {
				toggleModal('showCookieExtendedSettings');
			}
			toggleModal('showCookieExtendedInfo');
		};
		const handleBack = () => {
			toggleModal('showCookieExtendedInfo');
		};
		const handleDiscard = async () => {
			const { dispatch } = this.props;
			this.setState({ showCookieExtendedInfo: false });
			dispatch(setShowModalNoMatterWhat(false));
			await switchCookie('marketing', false);
			await switchCookie('analytics', false);
			await switchCookie('functional', false);
			this.setCookies();
		};

		const handleSettings = () => {
			toggleModal('showCookieExtendedSettings');
		};
		return (
			(showModalNoMatterWhat || showCookieExtendedInfo) && (
				<StyledCookiesExtendedWrapper
					id="extended-info-wrapper"
					onMouseEnter={this.disableScroll}
					onMouseLeave={this.enableScroll}
					onTouchStart={this.disableScroll}
					onTouchEnd={this.enableScroll}
					onTouchCancel={this.enableScroll}
				>
					<StyledHeadersWrapper>
						<h1
							className={!showCookieExtendedSettings ? 'active underline-animation' : ''}
							data-cursor="blend-mode-circle"
							onClick={(e) => {
								if (showCookieExtendedSettings) {
									handleSettings();
								}
							}}
						>
							{generalHeader}
						</h1>
						<h1
							className={showCookieExtendedSettings ? 'active underline-animation' : ''}
							data-cursor="blend-mode-circle"
							onClick={(e) => {
								if (!showCookieExtendedSettings) {
									handleSettings();
								}
							}}
						>
							{configHeader}
						</h1>
					</StyledHeadersWrapper>

					{showCookieExtendedSettings ? (
						<StyledCookiesWrapper>
							<StyledCookie>
								<h2>{necessaryHeader}</h2>
								<StyledParagraphAndSwitchWrapper>
									<div dangerouslySetInnerHTML={getHtmlFromRedactorField(necessaryDescription)} />
									<label className="switch" id="necessary">
										<input type="checkbox" defaultChecked onClick={(e) => e.preventDefault()} />
										<span className="slider round first" />
									</label>
								</StyledParagraphAndSwitchWrapper>
							</StyledCookie>
							{cookiesArray.length !== 0 &&
								cookiesArray.map((cookie, index) => {
									const cookieGroup = formatCookieName(cookie?.cookiesId) || '';
									const header = cookie?.cookiesHeader || '';
									const description = cookie?.cookiesDescription || '';
									const link = cookie?.cookiesLink || '';
									return (
										<StyledCookie key={index}>
											<h2>{header}</h2>
											<StyledParagraphAndSwitchWrapper>
												<div dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
												<label className="switch" data-cursor="blend-mode-circle">
													<input
														type="checkbox"
														className={cookieGroup}
														checked={cookiesSettings[cookieGroup]}
														onChange={(e) => switchCookie(cookieGroup, e.target.checked)}
													/>
													<span className="slider round" />
												</label>
											</StyledParagraphAndSwitchWrapper>
											{link && (
												<div
													data-cursor="blend-mode-circle"
													dangerouslySetInnerHTML={getHtmlFromRedactorField(link)}
												/>
											)}
										</StyledCookie>
									);
								})}
						</StyledCookiesWrapper>
					) : (
						<StyledParagraph>
							<div dangerouslySetInnerHTML={getHtmlFromRedactorField(generalDescription)} />
						</StyledParagraph>
					)}

					<StyledButtonsWrapper config>
						<AnimatedLink title={discardButtonText} onClick={handleDiscard} />
						{showCookieExtendedSettings ? (
							<>
								<AnimatedLink title={saveButtonText} onClick={handleAccept} />
							</>
						) : (
							<>
								<AnimatedLink title={configHeader} onClick={handleSettings} />
							</>
						)}
						<AnimatedLink title={saveAllButtonText} onClick={handleAcceptAll} />
					</StyledButtonsWrapper>
				</StyledCookiesExtendedWrapper>
			)
		);
	}
}

export default connect((state) => ({
	necessary: state.necessaryCookies.necessaryCookies,
	showModalNoMatterWhat: state.necessaryCookies.showModalNoMatterWhat,
	introStartModule: state.introStartModule.introStartModule,
}))(CookieInfo);
