import { createGlobalStyle } from 'styled-components';

const FooterStyle = createGlobalStyle`
  #footer{
	span{
	  &.footer-info{
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        line-height: 20px;
        color: ${({ theme }) => theme.colors.grey};
		&.address{
		  text-decoration: none !important;
          color: ${({ theme }) => theme.colors.grey} !important;
		}
	  }
	  &.footer-header-text{
        font-family: ${({ theme }) => theme.fontFamily.primary};
        color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.fontWeight.light};
        font-size: 64px;
        line-height: 72px;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 34px;
        }
	  }
	}
	a {
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      font-size: 14px;
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      line-height: 30px;
      color: ${({ theme }) => theme.colors.white};
      &.contact{
        margin-right: 27px;
		:hover + a,button{
		  transform: scale(1.22);
		}
		&.active{
          color: ${({ theme }) => theme.colors.lightBrown};
		}
	  }
      :hover{
        color: ${({ theme }) => theme.colors.lightBrown};
        span {
          color: ${({ theme }) => theme.colors.lightBrown};
        }
      }
	}

    div {
      .inner-html{
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        font-size: 14px;
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        letter-spacing: 0;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.grey};
        margin-top: 3vh;
        @media(max-width: 767px) {
          margin-top: 3vh;
        }
        @media (min-width: 768px) and (max-width: 1365px) {
          margin-top: 3vh;
        }
      }
    }
  }
`;
export default FooterStyle;
