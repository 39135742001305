import { createSlice } from '@reduxjs/toolkit';

export const menuColor = createSlice({
	name: 'menuColor',
	initialState: {
		menuColor: false,
	},
	reducers: {
		setMenuColor: (state, action) => {
			state.menuColor = action.payload;
		},
	},
});

export const { setMenuColor } = menuColor.actions;

export default menuColor.reducer;
