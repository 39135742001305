import { createSlice } from '@reduxjs/toolkit';

export const isAskAboutPricePopupOpen = createSlice({
	name: 'isAskAboutPricePopupOpen',
	initialState: {
		isAskAboutPricePopupOpen: false,
	},
	reducers: {
		setIsAskAboutPricePopupOpen: (state, action) => {
			state.isAskAboutPricePopupOpen = action.payload;
		},
	},
});

export const { setIsAskAboutPricePopupOpen } = isAskAboutPricePopupOpen.actions;

export default isAskAboutPricePopupOpen.reducer;
