// eslint-disable-next-line no-unused-vars
const setInitialColors = (pageImmutableId, isModelTabActive) => {
	// eslint-disable-next-line radix
	// if ((pageImmutableId === 'apartments_id' && isModelTabActive) || pageImmutableId === 'foodstories_id') {
	if (pageImmutableId === 'foodstories_id') {
		document.documentElement.style.setProperty('--background-color', 'rgb(30,30,30)');
		document.documentElement.style.setProperty('--menu-color', 'rgb(247,245,242)');
	} else if (pageImmutableId === 'noho_id') {
		document.documentElement.style.setProperty('--background-color', 'rgba(247, 245, 242, 1)');
		document.documentElement.style.setProperty('--menu-color', 'rgb(247,245,242)');
	} else {
		document.documentElement.style.setProperty('--background-color', 'rgba(247, 245, 242, 1)');
		document.documentElement.style.setProperty('--menu-color', 'rgb(31,48,45)');
	}
};

export default setInitialColors;
