import { createSlice } from '@reduxjs/toolkit';

export const necessaryCookiesSlice = createSlice({
	name: 'necessaryCookies',
	initialState: {
		necessaryCookies: false,
		showModalNoMatterWhat: false,
	},
	reducers: {
		setNecessaryCookies: (state) => {
			state.necessaryCookies = !state.necessaryCookies;
		},
		setShowModalNoMatterWhat: (state, action) => {
			state.showModalNoMatterWhat = action.payload;
		},
	},
});

export const { setNecessaryCookies, setShowModalNoMatterWhat } = necessaryCookiesSlice.actions;

export default necessaryCookiesSlice.reducer;
