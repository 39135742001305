import { gsap } from 'gsap';
import imgRevealAnimation from './imgRevealAnimation';
import textAnimation from './textAnimation';
import firstTextAnimation from './FirstTextAnimation';
import photoDescriptionAnimation from './photoDescriptionAnimation';
import htmlBgColor from './htmlBgColor';
import textBtnAnimation from './textBtnAnimation';

const gotoAnimate = (i, dataType, anim) => {
	if (dataType === 'text-second') {
		const text = document.querySelector('.text-second');
		textAnimation(text);
	}
	if (dataType === 'text-third') {
		const text = document.querySelector('.text-third');
		textAnimation(text);
	}
	if (dataType === 'header-desc') {
		const headerDesc = document.querySelector(`.text-description-${i}`);
		const textDesc = document.querySelector(`.text-description-2-${i}`);
		firstTextAnimation(headerDesc);
		firstTextAnimation(textDesc);
	}
	if (dataType === 'header-description') {
		const headerDesc = document.querySelector(`.text-desc-${i}`);
		const textDesc = document.querySelector(`.text-desc-2-${i}`);

		firstTextAnimation(headerDesc);
		firstTextAnimation(textDesc);
	}
	if (dataType === 'photo-desc') {
		const tl9 = gsap.timeline();
		const img = document.querySelector('.img-desc');
		const desc = document.querySelector('.desc');
		const cover = document.querySelector('.cover');
		photoDescriptionAnimation(tl9, img, cover);
		gsap.fromTo(
			desc,
			{
				opacity: 0,
				y: '0px',
				ease: 'power4.easeOut',
				duration: 0.25,
			},
			{
				opacity: 1,
				y: '-100px',
				ease: 'power4.easeOut',
				duration: 0.65,
			},
		);
	}

	if (dataType === 'one-image') {
		const tl3 = gsap.timeline();
		const reveal = anim.childNodes[1];
		imgRevealAnimation(tl3, reveal);
	}
	if (dataType === 'one-image-right') {
		const tl6 = gsap.timeline();
		const reveal = document.querySelector('.reveal-right');

		imgRevealAnimation(tl6, reveal);
	}
	if (dataType === 'photos') {
		const covers = gsap.utils.toArray('.cover');
		covers.map((item) => {
			const tl4 = gsap.timeline();
			imgRevealAnimation(tl4, item);
		});
	}
	if (dataType === 'img-header-desc') {
		const foundersHeader = document.querySelector('.founders-header');
		const covers = gsap.utils.toArray('.cover-founder');
		const founderName = gsap.utils.toArray('.founder-name');
		const founderDesc = gsap.utils.toArray('.founder-desc');
		firstTextAnimation(foundersHeader);
		founderName.map((item) => {
			firstTextAnimation(item);
		});
		founderDesc.map((item) => {
			firstTextAnimation(item);
		});
		covers.map((item) => {
			const tl8 = gsap.timeline();
			imgRevealAnimation(tl8, item);
		});
	}
	if (dataType === 'first-text') {
		const firstText = document.querySelector('.first-text');
		firstTextAnimation(firstText);
	}
	if (dataType === 'numbers') {
		const numbers = document.querySelector('.numbers');
		textAnimation(numbers);
	}
	if (dataType === 'text') {
		firstTextAnimation(anim);
	}
	if (dataType === 'text-desc') {
		const textDesc = document.querySelector('.text-desc');
		const activeBtn = document.querySelector('.btn');
		const tl11 = gsap.timeline();
		// console.log(activeBtn);
		textBtnAnimation(tl11, textDesc, activeBtn);
	}
	if (dataType === 'html-bg-color') {
		htmlBgColor(anim);
	}
};
export default gotoAnimate;
