import styled from 'styled-components';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import icon360 from '/src/assets/image/360/360-degrees.png';

const StyledCustomCursor = styled.div`
	-webkit-font-smoothing: antialiased;
	//background-color: mediumvioletred;
	align-items: center;
	background-color: ${({ isArrowMode, theme }) => (isArrowMode ? 'transparent' : theme.colors.lightBrown)};
	border-radius: 100%;
	content: '';
	display: block;
	//height: 8px;
	height: ${({ isAmenities, isAmenitiesBlend }) => (isAmenities || isAmenitiesBlend ? '0' : '8px')};
	justify-content: center;
	letter-spacing: 1.5px;
	opacity: ${({ isBlendMode }) => (isBlendMode ? 0 : 1)};
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	text-transform: uppercase;
	transition: transform 0s ease-out, background-color 0.3s;
	visibility: visible;
	width: ${({ isAmenities }) => (isAmenities ? '0' : '8px')};
	will-change: transform;
	z-index: 151;

	&.hidden {
		visibility: hidden;
	}
	&.intro-mode {
		background-color: transparent;
		&:after {
			color: white;
			content: 'SKIP';
			display: inline-block;
			font-size: 21px;
			height: 100%;
			left: 2px;
			position: absolute;
			top: 10px;
			width: 100%;
		}
	}
	&.arrow-mode {
		&:after {
			content: ${({ icon360 }) => `url(${icon360})`};
			display: block;
			height: 100%;
			left: -15px;
			position: absolute;
			top: -14px;
			transform: scale(0.06);
			transition: all 0.2s ease-in-out;
			width: 100%;
		}
	}
	span {
		display: ${({ isAmenities }) => (isAmenities ? 'block' : 'none')};
		transform: translateY(-10px) translateX(-50%);
		position: absolute;
		left: 0;
		color: ${({ isAmenitiesBlend }) => (isAmenitiesBlend ? '#FFF' : '#a77f55')};
		font-family: Ivy Mode;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: uppercase;
		&:before {
			content: '';
			width: 118px;
			height: 118px;
			border-radius: 50%;
			border: 2px solid #616161;
			//border: 2px solid red;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;

const StyledCustomCursorAmenities = styled.div`
	-webkit-font-smoothing: antialiased;
	//background-color: mediumvioletred;
	align-items: center;
	background-color: ${({ isArrowMode, theme }) => (isArrowMode ? 'transparent' : theme.colors.lightBrown)};
	border-radius: 100%;
	content: '';
	display: block;
	height: 8px;
	justify-content: center;
	letter-spacing: 1.5px;
	opacity: ${({ isBlendMode }) => (isBlendMode ? 0 : 1)};
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	text-transform: uppercase;
	transition: transform 0s ease-out, background-color 0.3s;
	visibility: visible;
	width: 8px;
	will-change: transform;
	z-index: 151;
	//span {
	//	color: red;
	//	font-size: 25px;
	//}
	&.hidden {
		visibility: hidden;
	}
	&.intro-mode {
		background-color: transparent;
		&:after {
			color: white;
			content: 'SKIP';
			display: inline-block;
			font-size: 21px;
			height: 100%;
			left: 2px;
			position: absolute;
			top: 10px;
			width: 100%;
		}
	}
	&.arrow-mode {
		&:after {
			content: ${({ icon360 }) => `url(${icon360})`};
			display: block;
			height: 100%;
			left: -15px;
			position: absolute;
			top: -14px;
			transform: scale(0.06);
			transition: all 0.2s ease-in-out;
			width: 100%;
		}
	}
`;

const StyledCustomCursorAfter = styled.div`
	-webkit-font-smoothing: antialiased;
	//transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
	backface-visibility: hidden;
	background: ${({ isBlendMode, isAmenitiesBlend }) => (isBlendMode || isAmenitiesBlend ? 'rgb(247, 245, 242)' : 'transparent')};
	border: 2px solid
		${({ theme, isBlendMode, isAmenities, isAmenitiesBlend }) =>
			isBlendMode ? 'rgb(247, 245, 242)' : isAmenities || isAmenitiesBlend ? 'transparent' : theme.colors.black};
	border-radius: 100%;
	content: '';
	// display: ${({ isAmenities }) => (isAmenities ? 'none' : 'block')};
	display: block;
	visibility: ${({ isAmenities }) => (isAmenities ? 'hidden' : 'visible')};
	filter: ${({ isBlendMode, isAmenitiesBlend }) => (isBlendMode || isAmenitiesBlend ? 'none' : 'contrast(0.3)')};
	height: ${({ isBlendMode, isAmenities, isAmenitiesBlend, isMoving }) =>
		isBlendMode && !isAmenities ? '56px' : !isMoving ? '56px' : isAmenities || isAmenitiesBlend ? '118px' : '40px'};
	left: 0;
	mix-blend-mode: ${({ isBlendMode, isAmenitiesBlend }) => (isBlendMode || isAmenitiesBlend ? 'difference' : 'unset')};
	perspective: 1000;
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	top: 0;
	transition: ${({ isBlendMode, isAmenities, isAmenitiesBlend, isMoving }) =>
		isBlendMode
			? 'transform 0.7s cubic-bezier(0.16, 1, 0.3, 1), width 0.7s cubic-bezier(0.16, 1, 0.3, 1),height 0.7s cubic-bezier(0.16, 1, 0.3, 1), background-color 0s linear'
			: isAmenitiesBlend && !isMoving
			? 'transform 0.7s cubic-bezier(0.16, 1, 0.3, 1) 0.2s, width 0.7s cubic-bezier(0.16, 1, 0.3, 1) 0.2s,height 0.7s cubic-bezier(0.16, 1, 0.3, 1) 0.2s, background-color 0s linear 0.2s'
			: isAmenitiesBlend && isMoving
			? '0.7s cubic-bezier(0.16, 1, 0.3, 1)'
			: isAmenities || isAmenitiesBlend
			? 'none'
			: '0.7s cubic-bezier(0.16, 1, 0.3, 1)'};
	//visibility: visible;
	width: ${({ isBlendMode, isAmenities, isAmenitiesBlend, isMoving }) =>
		isBlendMode && !isAmenities ? '56px' : !isMoving ? '56px' : isAmenities || isAmenitiesBlend ? '118px' : '40px'};
	z-index: 150;
	&.blend-mode-transform-animate {
		transition: background-color 0.7s cubic-bezier(0.16, 1, 0.3, 1), transform 0s linear;
	}
	&.intro-mode {
		border: 2px solid ${({ theme }) => theme.colors.gold};
		filter: none;
		height: ${({ isBlendMode }) => (isBlendMode ? '96px' : '80px')};
		width: ${({ isBlendMode }) => (isBlendMode ? '96px' : '80px')};
	}
	&.arrow-mode {
		background-color: ${({ theme }) => theme.colors.lightBrown};
		border: 2px solid ${({ theme }) => theme.colors.lightBrown};
		filter: none;
		z-index: 150;
	}
	&.hidden {
		transition: 0s;
		visibility: hidden;
	}
	span {
		display: none;
		// display: ${({ isAmenitiesBlend }) => (isAmenitiesBlend ? 'block' : 'none')};
		// transform: translateY(-50%) translateX(-50%);
		// position: absolute;
		// left: 50%;
		// top: 50%;
		// //position: relative;
		// z-index: 10;
		// color: ${({ isAmenitiesBlend }) => (isAmenitiesBlend ? 'black' : '#a77f55')};
		// font-family: Ivy Mode;
		// font-size: 16px;
		// font-style: normal;
		// font-weight: 600;
		// line-height: normal;
		// text-transform: uppercase;
		// mix-blend-mode: normal;
	}
`;

const StyledCustomCursorAfterAmenities = styled.div`
	-webkit-font-smoothing: antialiased;
	//transition: 0.7s cubic-bezier(0.16, 1, 0.3, 1);
	backface-visibility: hidden;
	background: ${({ isBlendMode }) => (isBlendMode ? 'rgb(247, 245, 242)' : 'transparent')};
	border: 2px solid ${({ theme, isBlendMode }) => (isBlendMode ? 'rgb(247, 245, 242)' : theme.colors.black)};
	border-radius: 100%;
	content: '';
	display: block;
	filter: ${({ isBlendMode }) => (isBlendMode ? 'none' : 'contrast(0.3)')};
	//height: ${({ isBlendMode }) => (isBlendMode ? '56px' : '40px')};
	height: ${({ isBlendMode, isAmenities }) => (isBlendMode && !isAmenities ? '56px' : isAmenities ? '118px' : '40px')};
	left: 0;
	mix-blend-mode: ${({ isBlendMode }) => (isBlendMode ? 'difference' : 'unset')};
	perspective: 1000;
	pointer-events: none;
	position: fixed;
	text-rendering: optimizeLegibility;
	top: 0;
	transition: ${({ isBlendMode }) =>
		isBlendMode
			? 'transform 0.7s cubic-bezier(0.16, 1, 0.3, 1), width 0.7s cubic-bezier(0.16, 1, 0.3, 1),height 0.7s cubic-bezier(0.16, 1, 0.3, 1), background-color 0s linear'
			: '0.7s cubic-bezier(0.16, 1, 0.3, 1)'};
	visibility: visible;
	width: ${({ isBlendMode, isAmenities }) => (isBlendMode && !isAmenities ? '56px' : isAmenities ? '118px' : '40px')};
	z-index: 150;
	&.blend-mode-transform-animate {
		transition: background-color 0.7s cubic-bezier(0.16, 1, 0.3, 1), transform 0s linear;
	}
	&.intro-mode {
		border: 2px solid ${({ theme }) => theme.colors.gold};
		filter: none;
		height: ${({ isBlendMode }) => (isBlendMode ? '96px' : '80px')};
		width: ${({ isBlendMode }) => (isBlendMode ? '96px' : '80px')};
	}
	&.arrow-mode {
		background-color: ${({ theme }) => theme.colors.lightBrown};
		border: 2px solid ${({ theme }) => theme.colors.lightBrown};
		filter: none;
		z-index: 150;
	}
	&.hidden {
		transition: 0s;
		visibility: hidden;
	}
	span {
		color: indianred;
		position: absolute;
		top: 0;
		font-size: 20px;
	}
`;

const StyledBlendWrapper = styled.div`
	position: relative;
	border: 2px solid red;
	span {
		position: absolute;
		top: 0;
		left: 0;
		color: red;
		z-index: 1000000;
	}
`;

class CustomCursor2 extends Component {
	_isMounted = false;
	state = {
		isLinkHovered: false,
		isMobileDevice: false,
		isBlendMode: false,
		isHiddenMode: false,
		isArrowMode: false,
		isAmenities: false,
		isAmenitiesBlend: false,
		isMoving: false,
	};

	mouse = { x: 0, y: 0 };

	lastUpdateCall = null;

	activeLinkItem = '';

	linkItems = [];

	componentDidMount() {
		this.setState({ isMobileDevice: isMobile });
		this._isMounted = true;

		this.customMenuCursor = document.getElementById('custom-cursor');
		this.customMenuCursorAfter = document.getElementById('custom-cursor-after');
		this.customMenuCursorAfterAmenities = document.getElementById('custom-cursor-after-amenities');
		document.addEventListener('mousemove', this.setCursorPosition);
		document.addEventListener('scroll', this.toggleLinkHoveredFalse);
	}

	timer;
	movementTimeout;

	addClassForBlendAnimation = () => {
		this.customMenuCursorAfter.classList.add('blend-mode-transform-animate');
	};

	componentDidUpdate(prevProps, prevState) {
		const { isBlendMode, isArrowMode } = this.state;

		// if (prevProps.backgroundColorState !== backgroundColorState) {
		// this.customMenuCursor.style.backgroundColor = backgroundColorState === 'black' ? `white` : 'black';
		// }

		if (prevState.isBlendMode !== isBlendMode) {
			if (isBlendMode) {
				this.timer = setTimeout(this.addClassForBlendAnimation, 700);
			} else {
				clearTimeout(this.timer);
				this.customMenuCursorAfter.classList.remove('blend-mode-transform-animate');
			}
		}
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	const { pageRemoteId, currentLang } = this.props;
	// 	const { isLinkHovered } = this.state;
	// 	if (prevProps.pageRemoteId !== pageRemoteId || prevProps.currentLang !== currentLang) {
	// 		this.removeAnimationsListeners();
	// 		this.addAnimationsListeners();
	// 	}
	//
	// 	// if (prevProps.backgroundColorState !== backgroundColorState) {
	// 	// this.customMenuCursor.style.backgroundColor = backgroundColorState === 'black' ? `white` : 'black';
	// 	// }
	// 	if (prevState.isLinkHovered !== isLinkHovered) {
	// 		if (isLinkHovered) {
	// 			const navItemBox = this.activeLinkItem.getBoundingClientRect();
	// 			const stuckX = Math.ceil(navItemBox.left + navItemBox.width / 2);
	// 			const stuckY = Math.ceil(navItemBox.top + navItemBox.height / 2);
	//
	// 			// const animationType = '';
	// 			if (this.activeLinkItem.classList.contains('custom-slider')) {
	// 				this.animationType = 'custom-slider';
	// 			} else if (this.activeLinkItem.classList.contains('rectangle-button')) {
	// 				this.animationType = 'rectangle';
	// 			} else if (
	// 				this.activeLinkItem.classList.contains('footer-social-media') ||
	// 				this.activeLinkItem.classList.contains('change-lang')
	// 			) {
	// 				this.animationType = 'circle-progress';
	// 			} else if (this.activeLinkItem.classList.contains('footer-email-address')) {
	// 				this.animationType = 'footer-email';
	// 			} else {
	// 				this.animationType = 'circle';
	// 			}
	//
	// 			if (this.animationType === 'circle') {
	// 				this.circleAnimation(stuckX, stuckY);
	// 			} else if (this.animationType === 'rectangle') {
	// 				this.rectangleAnimation(stuckX, stuckY);
	// 			} else if (this.animationType === 'circle-progress') {
	// 				this.circleProgressAnimation();
	// 			} else if (this.animationType === 'footer-email') {
	// 				this.sliderAnimation();
	// 			} else if (this.animationType === 'custom-slider') {
	// 				this.sliderAnimation();
	// 			}
	// 		}
	// 	}
	// }

	componentWillUnmount() {
		this._isMounted = false;
		this.lastUpdateCall = null;
		if (this.movementTimeout) {
			clearTimeout(this.movementTimeout);
		}
		document.removeEventListener('mousemove', this.setCursorPosition);
		document.removeEventListener('scroll', this.toggleLinkHoveredFalse);
		// this.removeAnimationsListeners();
	}
	// addAnimationsListeners = () => {
	// 	this.linkItems = document.querySelectorAll('.custom-cursor-animation');
	// 	this.linkItems.forEach((item) => {
	// 		item.addEventListener('mouseover', (e) => this.toggleLinkHoveredTrue(e));
	// 		item.addEventListener('mouseout', (e) => this.toggleLinkHoveredFalse(e));
	// 	});
	// };

	// removeAnimationsListeners = () => {
	// 	this.linkItems.forEach((item) => {
	// 		item.removeEventListener('mouseover', (e) => this.toggleLinkHoveredTrue(e));
	// 		item.removeEventListener('mouseout', (e) => this.toggleLinkHoveredFalse(e));
	// 	});
	// };

	// circleAnimation = (stuckX, stuckY) => {
	// 	// line animation on hover +32px, border width x2 +4px, 32 - 4 = 28
	// 	const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 36;
	// 	// half of border +1px
	// 	const animatedCursorPositionTranslate = Math.floor((this.activeLinkItem.clientWidth + 36) / 2);
	// 	this.customMenuCursorAfter.style.width = `${animatedCursorCircleWidth}px`;
	// 	this.customMenuCursorAfter.style.height = `${animatedCursorCircleWidth}px`;
	// 	this.customMenuCursorAfter.style.transform = `translate3d(${stuckX - animatedCursorPositionTranslate}px, ${
	// 		stuckY - animatedCursorPositionTranslate
	// 	}px, 0px)`;
	// };
	//
	// rectangleAnimation = (stuckX, stuckY) => {
	// 	this.customMenuCursorAfter.style.display = 'none';
	// 	const animatedCursorCircleWidth = this.activeLinkItem.clientWidth;
	// 	const animatedCursorCircleHeight = this.activeLinkItem.clientHeight;
	// 	// const animatedCursorCircleWidth = this.activeLinkItem.clientWidth + 20;
	// 	// const animatedCursorCircleHeight = this.activeLinkItem.clientHeight + 20;
	// 	this.customMenuCursorAfterRectangle.style.opacity = `1`;
	// 	this.customMenuCursorAfterRectangle.style.width = `${animatedCursorCircleWidth}px`;
	// 	this.customMenuCursorAfterRectangle.style.height = `${animatedCursorCircleHeight}px`;
	// 	const animatedCursorPositionTranslateX = Math.floor(animatedCursorCircleWidth / 2 + 1);
	// 	const animatedCursorPositionTranslateY = Math.floor(animatedCursorCircleHeight / 2);
	// 	this.customMenuCursorAfterRectangle.style.transform = `translate3d(${stuckX - animatedCursorPositionTranslateX}px, ${
	// 		stuckY - animatedCursorPositionTranslateY
	// 	}px, 0px)`;
	// };

	circleProgressAnimation = () => {
		this.customMenuCursorAfter.style.display = 'none';
	};

	getCursorPosition = () => this.customMenuCursor.getBoundingClientRect();

	// cleanAnimationFantastic = () => {
	// 	const cursorPosition = this.customMenuCursor.getBoundingClientRect();
	// 	this.customMenuCursorAfter.style.transform = `translate3d(${cursorPosition.x - 15}px, ${cursorPosition.y - 15}px, 0px)`;
	// 	this.customMenuCursorAfter.style.display = `block`;
	// 	this.customMenuCursorAfter.style.width = `34px`;
	// 	this.customMenuCursorAfter.style.height = `34px`;
	// 	this.customMenuCursor.style.visibility = 'visible';
	// 	// eslint-disable-next-line no-template-curly-in-string
	// 	this.customMenuCursor.style.backgroundColor = " ${({ isMenuVisible }) => (isMenuVisible ? 'white' : 'var(--logo-color)')}";
	// 	this.customMenuCursorAfter.style.borderRadius = `100%`;
	// 	this.customMenuCursorAfterRectangle.style.width = `34px`;
	// 	this.customMenuCursorAfterRectangle.style.height = `34px`;
	// 	this.customMenuCursorAfterRectangle.style.opacity = `0`;
	// };

	setCursorPosition = (e) => {
		const { isLinkHovered } = this.state;
		const { topMovement } = this.props;

		if (!this.state.isMoving) {
			this.setState({ isMoving: true });
		}
		if (this.movementTimeout) {
			clearTimeout(this.movementTimeout);
		}

		this.movementTimeout = setTimeout(() => {
			this.setState({ isMoving: false });
			document.body.classList.remove('moving');
		}, 100); // Adjust timeout as needed

		const cursorSet = e.target.closest('[data-cursor]');
		// this.customMenuCursor?.classList?.add('cursor-black');
		// this.customMenuCursor?.classList?.remove('cursor-white');

		if (cursorSet && cursorSet.getAttribute('data-cursor') === 'blend-mode-circle') {
			this.setState({ isAmenities: false });
			this.setState({ isBlendMode: true });
			this.setState({ isHiddenMode: false });
			this.setState({ isArrowMode: false });
			this.setState({ isAmenitiesBlend: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'hidden-mode') {
			this.setState({ isAmenities: false });
			this.setState({ isBlendMode: false });
			this.setState({ isHiddenMode: true });
			this.setState({ isArrowMode: false });
			this.setState({ isAmenitiesBlend: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'arrow-mode') {
			this.setState({ isAmenities: false });
			this.setState({ isBlendMode: false });
			this.setState({ isHiddenMode: false });
			this.setState({ isArrowMode: true });
			this.setState({ isAmenitiesBlend: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'blend-mode-circle-amenities') {
			this.setState({ isAmenities: true });
			this.setState({ isBlendMode: false });
			this.setState({ isHiddenMode: false });
			this.setState({ isArrowMode: false });
			this.setState({ isAmenitiesBlend: false });
		} else if (cursorSet && cursorSet.getAttribute('data-cursor') === 'blend-mode-circle-amenities2') {
			// this.setState({ isAmenities: true });
			this.setState({ isAmenities: false });
			this.setState({ isBlendMode: false });
			this.setState({ isHiddenMode: false });
			this.setState({ isArrowMode: false });
			this.setState({ isAmenitiesBlend: true });
		} else {
			this.setState({ isAmenities: false });
			this.setState({ isBlendMode: false });
			this.setState({ isHiddenMode: false });
			this.setState({ isArrowMode: false });
			this.setState({ isAmenitiesBlend: false });
		}

		this.customMenuCursor.style.transform = `translate3d(${e.clientX}px, ${e.clientY + topMovement}px, 0px)`;
		if (this.lastUpdateCall) {
			cancelAnimationFrame(this.lastUpdateCall);
		}
		if (!isLinkHovered) {
			this.lastUpdateCall = requestAnimationFrame(() => this.setCursorAfterPosition(e));
		}
	};

	setCursorAfterPosition = (e) => {
		const { isBlendMode, isAmenities, isAmenitiesBlend } = this.state;
		// this.customMenuCursorAfter.style.transform = `translate3d(${e.clientX - 16}px, ${e.clientY - 16}px, 0px)`;
		// if (isAmenities) {
		// 	this.customMenuCursorAfterAmenities.style.transform = `translate3d(${e.clientX - 26}px, ${e.clientY - 26}px, 0px)`;
		// }
		if (isBlendMode) {
			this.customMenuCursorAfter.style.transform = `translate3d(${e.clientX - 26}px, ${e.clientY - 26}px, 0px)`;
		} else if (isAmenities || isAmenitiesBlend) {
			this.customMenuCursorAfter.style.transform = `translate3d(${e.clientX - 59}px, ${e.clientY - 59}px, 0px)`;
		} else {
			this.customMenuCursorAfter.style.transform = `translate3d(${e.clientX - 16}px, ${e.clientY - 16}px, 0px)`;
		}
		this.lastUpdateCall = null;
	};

	toggleLinkHoveredTrue = (e) => {
		const { isLinkHovered } = this.state;
		if (!isLinkHovered) {
			e.stopPropagation();
			this.activeLinkItem = e.currentTarget;
			this.activeLinkItem?.classList?.add('hover');
			this.setState({ isLinkHovered: true });
		}
	};

	toggleLinkHoveredFalse = (e) => {
		const { isLinkHovered } = this.state;
		if (isLinkHovered) {
			this.activeLinkItem?.classList?.remove('hover');
			e.stopPropagation();
			// this.cleanAnimationFantastic();
			this.setState({ isLinkHovered: false });
		}
	};

	render() {
		const { isMobileDevice, isBlendMode, isHiddenMode, isArrowMode, isAmenities, isAmenitiesBlend, isMoving, isIntroMode } = this.state;
		const { topMovement, introStartModule } = this.props;

		return (
			<>
				<StyledCustomCursor
					isBlendMode={isBlendMode}
					isAmenities={isAmenities}
					isAmenitiesBlend={isAmenitiesBlend}
					isArrowMode={isArrowMode}
					icon360={icon360}
					// isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}
					isMobileDevice={isMobileDevice}
					// isMenuVisible={isMenuVisible}
					className={`${isHiddenMode ? 'hidden' : ''} ${isArrowMode ? 'arrow-mode' : ''}`}
					id="custom-cursor"
				>
					<span>PRZESUŃ</span>
				</StyledCustomCursor>

				{/*{isAmenities && (*/}
				{/*	<StyledCustomCursorAmenities*/}
				{/*		isBlendMode={isBlendMode}*/}
				{/*		isArrowMode={isArrowMode}*/}
				{/*		icon360={icon360}*/}
				{/*		// isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}*/}
				{/*		isMobileDevice={isMobileDevice}*/}
				{/*		// isMenuVisible={isMenuVisible}*/}
				{/*		className={`${isHiddenMode ? 'hidden' : ''} ${isArrowMode ? 'arrow-mode' : ''}`}*/}
				{/*		id="custom-cursor"*/}
				{/*	>*/}
				{/*		<span>PRZESUŃ</span>*/}
				{/*	</StyledCustomCursorAmenities>*/}
				{/*)}*/}

				<StyledCustomCursorAfter
					isBlendMode={isBlendMode}
					isAmenities={isAmenities}
					isAmenitiesBlend={isAmenitiesBlend}
					isMoving={isMoving}
					// isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}
					isMobileDevice={isMobileDevice}
					id="custom-cursor-after"
					className={`${isHiddenMode ? 'hidden' : ''} ${isArrowMode ? 'arrow-mode' : ''} `}
				>
					<span>{isMoving ? 'PRZESUŃ' : 'otwórz'}</span>
					{/*<span>{isMoving.}</span>*/}
				</StyledCustomCursorAfter>
				{/*<StyledCustomCursorAfterAmenities*/}
				{/*	isBlendMode={isBlendMode}*/}
				{/*	isAmenities={isAmenities}*/}
				{/*	// isOverSlider={isSlider || isNews || isVerticalSlider || isVideoModule || isGallery}*/}
				{/*	isMobileDevice={isMobileDevice}*/}
				{/*	id="custom-cursor-after-amenities"*/}
				{/*	className={`${isHiddenMode ? 'hidden' : ''} ${isArrowMode ? 'arrow-mode' : ''} `}*/}
				{/*>*/}
				{/*	<span>OTWÓRZ</span>*/}
				{/*</StyledCustomCursorAfterAmenities>*/}
			</>
		);
	}
}

export default connect((state) => ({
	isDropdownMenuOpen: state.isDropdownMenuOpen.isDropdownMenuOpen,
	topMovement: state.isDropdownMenuOpen.topMovement,
	introStartModule: state.introStartModule.introStartModule,
}))(CustomCursor2);
