import React from 'react';
import { Link } from 'gatsby';
import ArrowRightSvg from '../assets/globals/arrow-right.svg';

const ArrowButton = ({ to, arrowLeft, onClick, onFooter }) =>
	!to ? (
		<button
			onClick={onClick}
			className={onFooter ? 'arrow-button on-footer' : 'arrow-button '}
			data-cursor={onFooter ? 'hidden-mode' : 'blend-mode-circle '}
		>
			{arrowLeft ? (
				<img style={{ transform: 'translate(-50%,-50%) rotate(180deg)' }} src={ArrowRightSvg} />
			) : (
				<img src={ArrowRightSvg} />
			)}
		</button>
	) : (
		<Link
			className={onFooter ? 'arrow-button on-footer' : 'arrow-button '}
			to={to}
			onClick={onClick}
			data-cursor={onFooter ? 'hidden-mode' : 'blend-mode-circle '}
		>
			{arrowLeft ? (
				<img style={{ transform: 'translate(-50%,-50%) rotate(180deg)' }} src={ArrowRightSvg} />
			) : (
				<img src={ArrowRightSvg} />
			)}
		</Link>
	);

export default ArrowButton;
