import React from 'react';
import FooterDesktop from './FooterDesktop';
import FooterTablet from './FooterTablet';
import FooterMobile from './FooterMobile';

const FooterWrapper = ({ footerData, activeLang, immutablePageId }) => (
	<>
		<FooterDesktop footerData={footerData} activeLang={activeLang} immutablePageId={immutablePageId} />
		<FooterTablet footerData={footerData} activeLang={activeLang} immutablePageId={immutablePageId} />
		<FooterMobile footerData={footerData} activeLang={activeLang} immutablePageId={immutablePageId} />
	</>
);

export default FooterWrapper;
