import { createSlice } from '@reduxjs/toolkit';

export const introLoadedOnce = createSlice({
	name: 'introLoadedOnce',
	initialState: {
		introLoadedOnce: true,
	},
	reducers: {
		setIntroLoadedOnce: (state, action) => {
			state.introLoadedOnce = action.payload;
		},
	},
});

export const { setIntroLoadedOnce } = introLoadedOnce.actions;

export default introLoadedOnce.reducer;
