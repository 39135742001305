import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin: 0 auto;
	max-width: 100%;
	&.full-width {
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: 100%;
	}
	&.content-wrapper {
		padding: 0 80px;
		max-width: 100%;
		width: 100%;
		@media (min-width: 768px) and (max-width: 1024px) {
			padding: 0 64px;
		}
		@media (max-width: 767px) {
			padding: 0 24px;
		}
	}
	&.content-standard {
		margin: 0 25px;
		@media (min-width: 768px) {
			margin: 0 65px;
		}
		@media (min-width: 1366px) {
			margin: 0 140px;
		}
		@media (min-width: 1920px) {
			max-width: 1510px;
			margin: 0 auto;
		}
	}
	&.content-standard-apartments {
		margin: 0 5px;
		@media (min-width: 768px) {
			margin: 0 65px;
		}
		@media (min-width: 1366px) {
			margin: 0 140px;
		}
		@media (min-width: 1920px) {
			max-width: 1510px;
			margin: 0 auto;
		}
	}
	&.menu {
		position: relative;
	}
`;

const PageContainer = ({ children, className, id }) => (
	<Container className={className} id={id}>
		{children}
	</Container>
);

export default PageContainer;
