import { gsap } from 'gsap';

export default async function firstTextAnimation(text) {
	gsap.to(text, {
		autoAlpha: 1,
		delay: 0.4,
		top: 0,
		duration: 0.85,
	});
}
